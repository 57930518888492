import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import '../src/assets/global.css'
Vue.config.productionTip = false
import axios from 'axios'

// axios.defaults.baseURL = 'http://192.168.0.109:8887'
axios.defaults.baseURL = 'api/'
// axios.defaults.baseURL = 'https://t-yx.gh199.cn/api/'
//请求拦截
//添加一个请求拦截器
axios.interceptors.request.use((config) => {
  //为请求头对象，添加token验证的Authorization字段
  // config.headers.Authorization = store.state.token
  config.headers.Authorization = window.sessionStorage.getItem('token')
  //在最后必须return
  // console.log(config);
  return config
})
Vue.prototype.$http = axios
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
