export default [{
  children: [{
      children: [
        {
          value: '集士港镇',
          label: '集士港镇'
        },
        {
          value: '古林镇',
          label: '古林镇'
        },
        {
          value: '高桥镇',
          label: '高桥镇'
        },
        {
          value: '横街镇',
          label: '横街镇'
        },
        {
          value: '鄞江镇',
          label: '鄞江镇'
        },
        {
          value: '洞桥镇',
          label: '洞桥镇'
        },
        {
          value: '章水镇',
          label: '章水镇'
        },
        {
          value: '龙观乡',
          label: '龙观乡'
        },
        {
          value: '石碶街道',
          label: '石碶街道'
        },
        {
          value: '江厦街道',
          label: '江厦街道'
        },
        {
          value: '月湖街道',
          label: '月湖街道'
        },
        {
          value: '鼓楼街道',
          label: '鼓楼街道'
        },
        {
          value: '南门街道',
          label: '南门街道'
        },  
        {
          value: '西门街道',
          label: '西门街道'
        },
        {
          value: '白云街道',
          label: '白云街道'
        },
        {
          value: '段塘街道',
          label: '段塘街道'
        },
        {
          value: '望春街道',
          label: '望春街道'
        },
      ],
      value: '海曙区',
      label: '海曙区'
    },
    // {
    //   children:[
    //     {
    //       value: '东胜街道',
    //       label: '东胜街道'
    //     },
    //     {
    //       value: '百丈街道',
    //       label: '百丈街道'
    //     },
    //     {
    //       value: '白鹤街道',
    //       label: '白鹤街道'
    //     },
    //     {
    //       value: '明楼街道',
    //       label: '明楼街道'
    //     },
    //     {
    //       value: '东柳街道',
    //       label: '东柳街道'
    //     },
    //     {
    //       value: '东郊街道',
    //       label: '东郊街道'
    //     },
    //     {
    //       value: '福明街道',
    //       label: '福明街道'
    //     },
    //     {
    //       value: '新明街道',
    //       label: '新明街道'
    //     },
    //   ],
    //   value: '江东区',
    //   label: '江东区'
    // },
    {
      children:[
        {
          value: '中马街道',
          label: '中马街道'
        },
        {
          value: '白沙街道',
          label: '白沙街道'
        },
        {
          value: '孔浦街道',
          label: '孔浦街道'
        },
        {
          value: '文教街道',
          label: '文教街道'
        },
        {
          value: '甬江街道',
          label: '甬江街道'
        },
        {
          value: '庄桥街道',
          label: '庄桥街道'
        },
        {
          value: '洪塘街道',
          label: '洪塘街道'
        },
        {
          value: '慈城镇',
          label: '慈城镇'
        },
      ],
      value: '江北区',
      label: '江北区'
    },
    {
      children:[
        {
          value: '大榭街道',
          label: '大榭街道'
        },
        {
          value: '新碶街道',
          label: '新碶街道'
        },
        {
          value: '小港街道',
          label: '小港街道'
        },
        {
          value: '大碶街道',
          label: '大碶街道'
        },
        {
          value: '霞浦街道',
          label: '霞浦街道'
        },
        {
          value: '柴桥街道',
          label: '柴桥街道'
        },
        {
          value: '戚家山街道',
          label: '戚家山街道'
        },
        {
          value: '春晓街道',
          label: '春晓街道'
        },
        {
          value: '梅山街道',
          label: '梅山街道'
        },
        {
          value: '郭巨街道',
          label: '郭巨街道'
        },
        {
          value: '白峰街道',
          label: '白峰街道'
        },
        {
          value: '保税区',
          label: '保税区'
        },
      ],
      value: '北仑区',
      label: '北仑区'
    },
    {
      children:[
        {
          value: '澥浦镇',
          label: '澥浦镇'
        },
        {
          value: '九龙湖镇',
          label: '九龙湖镇'
        },
        {
          value: '招宝山街道',
          label: '招宝山街道'
        },
        {
          value: '蛟川街道',
          label: '蛟川街道'
        },
        {
          value: '骆驼街道',
          label: '骆驼街道'
        },
        {
          value: '贵驷街道',
          label: '贵驷街道'
        },
        {
          value: '庄市街道',
          label: '庄市街道'
        },
      ],
      value: '镇海区',
      label: '镇海区'
    },
    {
      children:[
        {
          value: '潘火街道',
          label: '潘火街道'
        },
        {
          value: '福明街道',
          label: '福明街道'
        },
        {
          value: '东柳街道',
          label: '东柳街道'
        },
        {
          value: '中河街道',
          label: '中河街道'
        },
        {
          value: '东郊街道',
          label: '东郊街道'
        },
        {
          value: '下应街道',
          label: '下应街道'
        },
        {
          value: '明楼街道',
          label: '明楼街道'
        },
        {
          value: '百丈街道',
          label: '百丈街道'
        },
        {
          value: '东胜街道',
          label: '东胜街道'
        },
        {
          value: '白鹤街道',
          label: '白鹤街道'
        },
        {
          value: '首南街道',
          label: '首南街道'
        },
        {
          value: '钟公庙街道',
          label: '钟公庙街道'
        },
      ],
      value: '鄞州区',
      label: '鄞州区'
    },
    {
      children:[
        {
          value: '钟公庙街道',
          label: '钟公庙街道'
        },
      ],
      value: '象山县',
      label: '象山县'
    },
    {
      value: '宁海县',
      label: '宁海县'
    },
    {
      value: '余姚市',
      label: '余姚市'
    },
    {
      value: '慈溪市',
      label: '慈溪市'
    },
    {
      value: '奉化市',
      label: '奉化市'
    }
  ],
  value: '宁波市',
  label: '宁波市'
}, ]