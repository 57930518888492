export default [{
  children: [{
      value: '海曙区',
      label: '海曙区'
    },
    {
      value: '北仑区',
      label: '北仑区'
    },
    {
      
      value: '镇海区',
      label: '镇海区'
    },
    {
     
      value: '鄞州区',
      label: '鄞州区'
    },
    {
      value: '象山县',
      label: '象山县'
    },
    {
      value: '宁海县',
      label: '宁海县'
    },
    {
      value: '余姚市',
      label: '余姚市'
    },
    {
      value: '慈溪市',
      label: '慈溪市'
    },
    {
      value: '奉化市',
      label: '奉化市'
    }
  ],
  value: '宁波市',
  label: '宁波市'
}, ]