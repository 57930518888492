//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
export default {
  name: 'VueQingyunOrderReport',

  data() {
    return {
      queryParams: {
        time1: undefined,
        time2: undefined,
        time3: undefined,
      },
      queryInfo: {
        current: 1,
        pageSize: 50,
      },
      queryInfo2: {
        current: 1,
        pageSize: 5,
      },
      orderStatistics: [],
      orderDetailList: [],
      totalOrderDetailList: [],
      // 重新渲染表格的状态
      refreshTable: true,
      isExpandAll: false,
      ListDialog: false,
      loading: false,
      IdLoading: false,
      total: 0,
      total2: 0,
      idList: [],
      date1: undefined,
      date2: undefined,
      date3: undefined,
      date4: undefined,
      date5: undefined,
      date6: undefined,
      totalOrderNum: 0,
      totalOrderPrice: 0,
    };
  },
  created() {
    // this.getOrderStatistics()
  },
  mounted() {

  },

  methods: {
    // arraySpanMethod({ row, column, rowIndex, columnIndex }) {
    //   // 合并行  产品名字相同合并
    //   if (columnIndex === 0) {
    //     if (rowIndex === 0 || row.street != this.orderStatistics[rowIndex - 1].street) {
    //       let rowspan = 0;
    //       this.orderStatistics.forEach(element => {
    //         if (element.street === row.street) {
    //           rowspan++;
    //         }
    //       });
    //       return [rowspan, 1];
    //     } else {
    //       return [0, 0];
    //     }
    //   }
    //   // //合并列 月份值都为1 的合并
    //   // if (columnIndex > 2) {
    //   //   let colspan = 0;
    //   //   let colkeys = Object.keys(row);
    //   //   let currentindex = columnIndex - 1;
    //   //   if (row[colkeys[currentindex]] === 1) {
    //   //     if (row[colkeys[currentindex - 1]] != row[colkeys[currentindex]]) {
    //   //       for (let i = currentindex; i < colkeys.length; i++) {
    //   //         if (row[colkeys[i]] === 1) {
    //   //           colspan++;
    //   //         } else {
    //   //           break;
    //   //         }
    //   //       }
    //   //       return [1, colspan];
    //   //     } else {
    //   //       return [0, 0];
    //   //     }
    //   //   }

    //   // }
    // },
    // 关闭列表对话框
    closeListDialog() { },
    // 点击数量，打开对话框
    async openIdList(list) {
      this.idList = list
      const { data: res } = await this.$http.post('/orders/findList', {
        data: list,
        page: {
          current: this.queryInfo2.current,
          pageSize: this.queryInfo2.pageSize,
        },
      })
      this.ListDialog = true
      this.orderDetailList = res.result.records
      this.total2 = res.result.total
    },
    //获取全部id列表
    async getTotalIdList() {
      this.IdLoading = true
      const { data: res } = await this.$http.post('/orders/findList', {
        data: this.idList,
        page: {
          current: 1,
          pageSize: 99999,
        },
      })
      if (res.code !== 100) return this.$message.error(res.msg)
      this.totalOrderDetailList = res.result.records

      setTimeout(() => {
        this.IdLoading = false
        this.exportExcel2('educe-table2', '分类汇总详细信息')
      }, 1000);
    },
    // 搜索按钮
    async handleQuery() {
      this.loading = true
      this.queryInfo2 = {
        current: 1,
        pageSize: 5,
      }
      if (this.queryParams.time1 !== undefined) {
        this.date1 = this.queryParams.time1[0]
        this.date2 = this.queryParams.time1[1]
      }
      if (this.queryParams.time2 !== undefined) {
        this.date3 = this.queryParams.time2[0]
        this.date4 = this.queryParams.time2[1]
      }
      if (this.queryParams.time3 !== undefined) {
        this.date5 = this.queryParams.time3[0]
        this.date6 = this.queryParams.time3[1]
      }
      const { data: res } = await this.$http.post('/orders/orderStatistics', {
        type: 2,
        date1: this.date1,
        date2: this.date2,
        date3: this.date3,
        date4: this.date4,
        date5: this.date5,
        date6: this.date6,
      })
      if (res.code !== 100) return this.$$message.error(res.msg)
      this.totalOrderNum = 0
      this.totalOrderPrice = 0
      for (let i = 0; i < res.result.length; i++) {
        let num = res.result[i].idList.length
        let price = res.result[i].money
        this.totalOrderNum += num
        this.totalOrderPrice += price
      }
      this.orderStatistics = res.result
      this.loading = false
    },
    resetQuery() {
      // this.loading = true
      this.queryInfo.current = 1
      this.queryInfo.pageSize = 50
      this.queryParams.time1 = undefined
      this.queryParams.time2 = undefined
      this.queryParams.time3 = undefined
      this.date1 = undefined
      this.date2 = undefined
      this.date3 = undefined
      this.date4 = undefined
      this.date5 = undefined
      this.date6 = undefined
      this.$refs.queryForm.resetFields()
      this.queryInfo2 = {
        current: 1,
        pageSize: 5,
      }
      this.orderStatistics = []
      // this.getOrderStatistics()
    },
    // 导出按钮
    exportExcel(elementName, fileName) {
      const wb = XLSX.utils.table_to_book(this.clearHead(elementName), { raw: true });
      const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' });
      FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
    },
    // 导出按钮
    exportExcel2(elementName, fileName) {
      const wb = XLSX.utils.table_to_book(this.clearHead(elementName), { raw: true });
      const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' });
      FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
    },
    // 导出时，去除展开和操作列
    clearHead(elementName) {
      const tableDom = document.querySelector('#' + elementName).cloneNode(true);
      const tableHeader = tableDom.querySelector('.el-table__header-wrapper');
      const tableBody = tableDom.querySelector('.el-table__body');
      tableHeader.childNodes[0].append(tableBody.childNodes[1]);
      const headerDom = tableHeader.childNodes[0].querySelectorAll('th');
      for (const key in headerDom) {
        if (headerDom[key].innerText === '操作' || headerDom[key].innerText === '查看') {
          headerDom[key].remove();
        }
      }
      // 清理掉checkbox 和操作的button
      const tableList = tableHeader.childNodes[0].childNodes[2].querySelectorAll('td');
      for (let key = 0; key < tableList.length; key++) {
        if (tableList[key].querySelectorAll('.el-button').length > 0) {
          tableList[key].remove();
        }
      }
      return tableHeader;
    },
    // 获取订单统计列表
    async getOrderStatistics() {
      const { data: res } = await this.$http.post('/orders/orderStatistics', {
        type: 2,
        date1: this.date1,
        date2: this.date2,
        date3: this.date3,
        date4: this.date4,
        date5: this.date5,
        date6: this.date6,
      })
      if (res.code !== 100) return this.$$message.error(res.msg)
      this.totalOrderNum = 0
      this.totalOrderPrice = 0
      for (let i = 0; i < res.result.length; i++) {
        let num = res.result[i].idList.length
        let price = res.result[i].money
        this.totalOrderNum += num
        this.totalOrderPrice += price
      }
      this.orderStatistics = res.result
      this.loading = false
    },
    //监听 pagesize改变事件
    async handleSizeChange2(newSize) {
      this.queryInfo2.pageSize = newSize
      const { data: res } = await this.$http.post('/orders/findList', {
        data: this.idList,
        page: {
          current: this.queryInfo2.current,
          pageSize: this.queryInfo2.pageSize,
        },
      })
      this.orderDetailList = res.result.records
      this.total2 = res.result.total
    },
    //监听页码值改变事件
    async handleCurrentChange2(newPage) {
      this.queryInfo2.current = newPage
      const { data: res } = await this.$http.post('/orders/findList', {
        data: this.idList,
        page: {
          current: this.queryInfo2.current,
          pageSize: this.queryInfo2.pageSize,
        },
      })
      this.orderDetailList = res.result.records
      this.total2 = res.result.total
    },
  },
};
