//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {
            //地址列表，模糊查询，必须传的参数
            labelList: [],
            searchVal: '',
            queryInfo: {
                current: 1,
                pageSize: 50,
            },
            queryInfo1: {
                current: 1,
                pageSize: 9999,
            },
            total: 0,
            userList: [],
            //级联选择框配置对象
            userProps: {
                value: 'id',
                label: 'userName',
            },
            //级联选择器双向绑定到的数组
            selectUserKeys: [],
            type2List: [],
            typeList: [],
            //级联选择框配置对象
            typeProps: {
                value: 'id',
                label: 'name',
                children: 'categoryList',
            },
            //级联选择器双向绑定到的数组
            selectTypeKeys: [],
            //添加地址的表单，验证规则
            addAddressDialog: false,
            addAddressForm: {},
            addAddressFormRules: {
                name: [{ required: true, message: '请输入联系人姓名', trigger: 'blur' }],
                phone: [
                    { required: true, message: '请输入联系电话', trigger: 'blur' },
                    {
                        pattern: /^1[34578]\d{9}$/,
                        message: '目前只支持中国大陆的手机号码',
                    },
                ],
                plateNumber: [{ required: true, message: '请输入车牌号', trigger: 'blur' }],

            },
            //编辑地址的表单，验证规则
            editAddressDialog: false,
            editAddressForm: {},
            editAddressFormRules: {
                name: [{ required: true, message: '请输入联系人姓名', trigger: 'blur' }],
                phone: [
                    { required: true, message: '请输入联系电话', trigger: 'blur' },
                    {
                        pattern: /^1[34578]\d{9}$/,
                        message: '目前只支持中国大陆的手机号码',
                    },
                ],
                plateNumber: [{ required: true, message: '请输入车牌号', trigger: 'blur' }],
            },
            selectEditUserKeys: [],
            selectEditTypeKeys: [],
            //查询对象
            queryParams: {},
            removeList: {},
            selectUserNameKeys: [],
            userNameList: [],
            userNameProps: {
                value: 'id',
                label: 'userName',
            },
            loading: true,
            isShow: true,
            //删除loading
            deleteLoading: false,
            addLoading: false,
            editLoading: false,

            streetKey: [],
            streetKey2: [],
            totalAddress: [],
            isshowTable: false,
            changeCompanyLoading: false,
            isName: false,
        }
    },
    created() {
        //加载页面时，获取地址列表
        this.getLabelList()
    },
    methods: {
        //搜索按钮
        async handleQuery() {
            this.loading = true
            const { data: res } = await this.$http.post('/tag/inquire', {
                data: {
                },
                page: {
                    current: this.queryInfo.current,
                    pageSize: this.queryInfo.pageSize,
                },
            })
            if (res.code !== 100) {
                this.loading = false
                return this.$message.error(res.msg)
            }
            this.loading = false
            this.labelList = res.result.records
            this.total = res.result.total
        },
        //重置按钮
        resetQuery() {
            this.loading = true
            this.$refs.queryForm.resetFields()
            this.queryParams = {}
            this.getLabelList()
        },
        //获取车辆列表
        async getLabelList() {
            this.loading = true
            const { data: res } = await this.$http.post('/tag/inquire', {
                page: {
                    current: this.queryInfo.current,
                    pageSize: this.queryInfo.pageSize,
                },
                data: {
                    isValid: 1,
                },
            })
            if (res.code !== 100) return this.$message.error('获取标签列表失败')
            this.labelList = res.result.records
            this.total = res.result.total
            this.loading = false
        },
        //打开添加地址对话框
        openAddressDialog() {
            this.addAddressDialog = true
        },
        //确定添加地址操作
        addCarAction() {
            this.$refs.addAddressFormRef.validate(async (valid) => {
                if (!valid) return
                this.addLoading = true
                const { data: res } = await this.$http.post('/tag/update', this.addAddressForm)
                if (res.code !== 100) {
                    this.addLoading = false
                    return this.$message.error(res.msg)
                }
                this.addLoading = false
                this.$message.success('添加成功')
                this.addAddressForm = []
                this.getLabelList()
                this.addAddressDialog = false
            })
        },
        //打开修改地址对话框
        async openEditDialog(id) {
            const { data: res } = await this.$http.post('/tag/getById', { id: id })
            if (res.code !== 100) return this.$message.error('查询失败')
            if (res.result.label == '小型企业' || res.result.label == '中型企业' || res.result.label == '大型企业') {
                this.isName = true
            }
            this.editAddressForm = res.result
            this.editAddressDialog = true
        },
        //修改地址操作
        editAddressAction() {
            this.$refs.editAddressFormRef.validate(async (valid) => {
                if (!valid) return
                this.editLoading = true
                const { data: res } = await this.$http.post('/tag/update', this.editAddressForm)
                if (res.code !== 100) {
                    this.editLoading = false
                    return this.$message.error(res.msg)
                }
                if (this.editAddressForm.label == '小型企业' || this.editAddressForm.label == '中型企业' || this.editAddressForm.label == '大型企业') {
                    this.$message.success('修改成功,正在修改公司标签')
                    setTimeout(() => {
                        this.editLoading = false
                        this.editAddressDialog = false
                        this.getLabelList()
                    }, 3000)
                } else {
                    this.editLoading = false
                    this.$message.success('修改成功')
                    this.editAddressDialog = false
                    this.getLabelList()
                }

            })
        },
        //删除地址操作
        async deleteAddress(id) {
            const confirmResult = await this.$confirm('此操作将永久删除该标签, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).catch((err) => err)
            if (confirmResult !== 'confirm') return this.$message.info('取消了删除')
            this.deleteLoading = true
            //查询id详细数据
            const { data: data } = await this.$http.post('/tag/getById', { id: id })
            this.removeList = data.result
            // 删除地址操作
            const { data: res } = await this.$http.post('/tag/delete', { ids: [this.removeList.id], standby: this.removeList.label })
            if (res.code !== 100) {
                this.deleteLoading = false
                return this.$message.error('删除车辆失败')
            }
            this.deleteLoading = false
            this.$message.success('删除车辆成功')
            this.getLabelList()
        },
        //监听 pagesize改变事件
        handleSizeChange(newSize) {
            this.queryInfo.pageSize = newSize
            this.getLabelList()
        },
        //监听页码值改变事件
        handleCurrentChange(newPage) {
            this.queryInfo.current = newPage
            this.getLabelList()
        },
        //关闭添加地址框
        //清除所填信息
        closeAddDialog() {
            // this.addAddressForm = {}
            this.$refs.addAddressFormRef.resetFields()
        },
        //关闭编辑地址框
        //清除所填信息
        closeEditDialog() {
            this.editAddressForm = {}
            this.isName = false
            this.$refs.editAddressFormRef.resetFields()
            this.editAddressDialog = false
        },
        //el-input无法输入 修复bug操作
        change(e) {
            this.$forceUpdate()
        },
    },
}
