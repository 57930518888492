//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      rolesList: [],
      searchVal: '',
      queryInfo: {
        current: 1,
        pageSize: 10,
      },
      total: 0,
      editRolesDialog: false,
      editRolesForm: {},
      editRolesFormRules: {
        roleName: [{ required: true, message: '请输入角色名', trigger: 'blur' }],
      },
      queryParams: {
        roleName: '',
      },
      addRolesDialog: false,
      addRolesForm: {},
      addRolesFormRules: {
        roleName: [{ required: true, message: '请输入角色名', trigger: 'blur' }],
      },
      removeList: {},
      loading: true,
      //删除loaidng
      deleteLoading: false,
      addLoading: false,
      editLoading:false,
    }
  },
  created() {
    this.getRolesList()
  },
  methods: {
    //搜索按钮
    // async handleQuery(){
    //   const {data:res} = await this.$http.post('/role/inquire',{
    //     data:{
    //       roleName:this.queryParams.roleName
    //     },
    //     page: {
    //       current: this.queryInfo.current,
    //       pageSize: this.queryInfo.pageSize,
    //     },
    //   })
    //   if(res.code !== 100) return this.$message.error('查询失败')
    //   this.rolesList = res.result
    // },
    //重置按钮
    resetQuery() {
      this.$refs.queryForm.resetFields()
      this.getRolesList()
    },
    //获取角色列表
    async getRolesList() {
      const { data: res } = await this.$http.post('/role/inquire', {
        page: {
          current: this.queryInfo.current,
          pageSize: this.queryInfo.pageSize,
        },
      })
      this.rolesList = res.result
      this.total = res.result.total
      this.loading = false
    },
    //打开添加角色对话框
    openRolesDialog() {
      this.addRolesDialog = true
    },
    //添加角色确认操作
    addRolesAction() {
      this.$refs.addRolesFormRef.validate(async (valid) => {
        if (!valid) return
        this.addLoading = true
        const { data: res } = await this.$http.post('/role/update', this.addRolesForm)
        if (res.code !== 100) {
          this.addLoading = false
          return this.$message.error(res.msg)
        }
        this.addLoading = false
        this.$message.success('添加角色成功')
        this.getRolesList()
        this.addRolesDialog = false
      })
    },
    //打开修改角色对话框
    async openEditDialog(id) {
      const { data: res } = await this.$http.post('/role/getById', { id: id })
      if (res.code !== 100) this.$message.error(res.msg)
      this.editRolesForm = res.result
      this.editRolesDialog = true
    },
    //修改角色确认操作
    editRolesAction() {
      this.$refs.editRolesFormRef.validate(async (valid) => {
        if (!valid) return
        this.editLoading = true
        const { data: res } = await this.$http.post('/role/update', this.editRolesForm)
        if (res.code !== 100){
          this.editLoading = false
          return this.$message.error(res.msg)
        } 
        this.editLoading = false
        this.$message.success('修改角色成功')
        this.getRolesList()
        this.editRolesDialog = false
      })
    },
    //关闭对话框 并清除内容
    closeEditRolesDialog() {
      this.$refs.editRolesFormRef.resetFields()
    },
    //删除角色
    async deleteUser(id) {
      const confirmResult = await this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).catch((err) => err)
      if (confirmResult !== 'confirm') return this.$message.info('取消了删除')
      this.deleteLoading = true
      const { data: data } = await this.$http.post('/role/getById', { id: id })
      this.removeList = data.result
      const { data: res } = await this.$http.post('/role/delete', { ids: [this.removeList.id], standby: this.removeList.roleName })
      if (res.code !== 100) {
        this.deleteLoading = false
        return this.$message.error(res.msg)
      }
      this.deleteLoading = false
      this.$message.success('删除角色成功')
      this.getRolesList()
    },
    //关闭对话框
    closeAddRolesDialog() {
      this.$refs.addRolesFormRef.resetFields()
    },
    //监听 pagesize改变事件
    // handleSizeChange(newSize) {
    //   this.queryInfo.pageSize = newSize
    //   this.getRolesList()
    // },
    // //监听页码值改变事件
    // handleCurrentChange(newPage) {
    //   this.queryInfo.current = newPage
    //   this.getRolesList()
    // },
  },
}
