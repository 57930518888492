//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
export default {
  data() {
    // 地区选择验证
    let validArea = (rule, value, callback) => {
      // 直接用value 获取不到选中的值 
      // 所以直接 用HTML中 v-model 绑定的值来判断 是否有值
      if (this.selectMoneyTypeKeys.length == 0) {
        callback(new Error('请选择充值类型'))
      } else {
        callback()
      }
    }
    return {
      loading: true,
      paymentList: [],
      queryInfo: {
        current: 1,
        pageSize: 50,
      },
      queryInfo1: {
        current: 1,
        pageSize: 9999,
      },
      total: 0,
      queryParams: {
        aid: undefined,
        time: undefined,
      },
      addPaymentDialog: false,
      addPaymentForm: {
        amount: '',
        operand: '',
      },
      addPaymentFormRules: {
        selectMoneyTypeKeys: [
          { required: true, validator: validArea, tirgger: 'blur' },
          { type: 'array', message: '请选择充值类型' }
        ],
      },
      selectCompanyKeys: [],
      //级联选择器双向绑定到的数组
      companyList: [],
      companyProps: {
        value: 'id',
        label: 'userName',
        children: 'categoryList'
      },
      selectPayKeys: '',
      payList: [],
      payProps: {
        value: 'id',
        label: 'userName',
      },
      //选择充值类型
      selectMoneyTypeKeys: [],
      selectMoneyTypeKeys2: [],
      MoneyTypeList: [],
      MoneyType2List: [],
      MoneyType3List: [],
      MoneyTypeProps: {
        value: 'id',
        label: 'name',
        children: 'categoryList',
      },
      MoneyTypeProps2: {
        value: 'name',
        label: 'name',
        children: 'categoryList',
      },
      //图片上传地址
      uploadURL: '/api/orders/upload',
      // uploadURL: 'http://192.168.2.49:8080/orders/upload',
      headerObj: {
        Authorization: window.sessionStorage.getItem('token'),
      },
      previewPath: '',
      previewVisible: false,
      // 充值loading
      addLoading: false,
      // 图片地址
      imageAddress: '',
      // 查看大图
      seepreviewPath: '',
      seepreviewVisible: false,
      isshowTable: false,
      totalpaymentList: [],
      beginTime: undefined, //开始时间
      finishTime: undefined, //结束时间
      changeCompanyLoading: false,
      // 选择导出数据数组
      daoChuChoseList: [],
    }
  },
  created() {
    this.getCompanyList()
    this.getPaymentList()
    this.getMoneyType()
  },
  methods: {
    // table选择框
    handleSelectionChange(val) {
      this.daoChuChoseList = val
      console.log(this.daoChuChoseList)
    },
    // input方法
    remoteMethod(query) {
      if (query !== '') {
        this.changeCompanyLoading = true
        setTimeout(() => {
          this.selectInput(query)
        }, 200)
      } else {
        this.getCompanyList()
        // this.conpanyList = []
      }
    },
    // 选择公司获取数据
    changeCompanyOption(item) {
      this.addPaymentForm.street = item.street
    },
    // 查询数据
    async selectInput(query) {
      const { data: res } = await this.$http.post('/company/query', {
        data: {
          userName: query,
        },
        page: {
          current: 1,
          pageSize: 10,
        },
      })
      this.payList = res.result.records
      this.changeCompanyLoading = false
    },
    // 第二种导出功能
    exportExcel(elementName, fileName) {
      const wb = XLSX.utils.table_to_book(this.clearHead(elementName), { raw: true });
      const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' });
      FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
    },
    // 导出时，去除展开和操作列
    clearHead(elementName) {
      const tableDom = document.querySelector('#' + elementName).cloneNode(true);
      const tableHeader = tableDom.querySelector('.el-table__header-wrapper');
      const tableBody = tableDom.querySelector('.el-table__body');
      tableHeader.childNodes[0].append(tableBody.childNodes[1]);
      const headerDom = tableHeader.childNodes[0].querySelectorAll('th');
      for (const key in headerDom) {
        if (headerDom[key].innerText === '操作' || headerDom[key].innerText === '查看') {
          headerDom[key].remove();
        }
      }
      // 清理掉checkbox 和操作的button
      const tableList = tableHeader.childNodes[0].childNodes[2].querySelectorAll('td');
      for (let key = 0; key < tableList.length; key++) {
        if (tableList[key].querySelectorAll('.el-button').length > 0) {
          tableList[key].remove();
        }
      }
      return tableHeader;
    },
    daochuAction() {
      if (this.daoChuChoseList.length == 0) {
        this.getTotalPaymentList()
      } else {
        this.choseDaoChuAction()
      }
    },
    choseDaoChuAction() {
      this.loading = true
      this.totalpaymentList = this.daoChuChoseList
      setTimeout(() => {
        this.loading = false
        this.$refs.multipleTable.clearSelection();
        this.exportExcel('educe-table', '预付款')
      }, 1000);
    },
    // 获取全部充值预付款
    async getTotalPaymentList() {
      this.loading = true
      const { data: res } = await this.$http.post('/advancePayment/query', {
        page: {
          current: 1,
          pageSize: 99999,
        },
        data: {
          aid: this.queryParams.aid,
          beginTime: this.beginTime,
          finishTime: this.finishTime,
          topUpName: this.queryParams.topUpName
        },
      })
      if (res.code !== 100) return this.$message.error('获取预付款列表失败')
      this.totalpaymentList = res.result.records
      setTimeout(() => {
        this.loading = false
        this.exportExcel('educe-table', '预付款')
      }, 1000);
    },
    //获取充值类型
    async getMoneyType() {
      const { data: res } = await this.$http.post('/caterory/query', {
        page: {
          current: this.queryInfo.current,
          pageSize: this.queryInfo.pageSize,
        },
      })
      if (res.code !== 100) return this.$message.error(res.msg)
      this.MoneyTypeList = res.result.records.filter((item) => item.id == 66)
      this.MoneyType2List = this.MoneyTypeList[0].categoryList
      this.MoneyType3List = JSON.parse(JSON.stringify(this.MoneyTypeList[0].categoryList))
      this.MoneyType3List.push({ name: '清运扣款' })
      this.MoneyType3List.push({ name: '到期清零' })
    },
    //搜索按钮
    async handleQuery() {
      if (this.queryParams.time !== undefined && this.queryParams.time !== null) {
        this.beginTime = this.queryParams.time[0]
        this.finishTime = this.queryParams.time[1]
      } else {
        this.beginTime = undefined
        this.finishTime = undefined
      }
      this.loading = true
      this.queryParams.aid = this.selectPayKeys
      const { data: res } = await this.$http.post('/advancePayment/query', {
        data: {
          aid: this.queryParams.aid,
          beginTime: this.beginTime,
          finishTime: this.finishTime,
          topUpName: this.queryParams.topUpName
        },
        page: {
          current: this.queryInfo.current,
          pageSize: this.queryInfo.pageSize,
        },
      })
      if (res.code !== 100) {
        this.loading = false
        return this.$message.error(res.msg)
      }
      this.loading = false
      this.paymentList = res.result.records
      this.total = res.result.total
    },
    //重置按钮
    resetQuery() {
      this.loading = true
      this.queryParams.aid = undefined
      this.finishTime = undefined
      this.beginTime = undefined
      this.selectPayKeys = ''
      this.selectMoneyTypeKeys2 = []
      this.queryParams.topUpName = undefined
      this.getCompanyList()
      this.queryInfo.current = 1
      this.queryInfo.pageSize = 50
      this.$refs.queryForm.resetFields()
      this.getPaymentList()
    },
    //获取预付款数据
    async getPaymentList() {
      this.loading = true
      const { data: res } = await this.$http.post('/advancePayment/query', {
        page: {
          current: this.queryInfo.current,
          pageSize: this.queryInfo.pageSize,
        },
        data: {
          aid: this.queryParams.aid,
          beginTime: this.beginTime,
          finishTime: this.finishTime,
          topUpName: this.queryParams.topUpName
        },
      })
      if (res.code !== 100) return this.$message.error('获取预付款列表失败')
      this.paymentList = res.result.records
      this.total = res.result.total
      this.loading = false
    },
    //获取公司信息
    async getCompanyList() {
      const { data: res } = await this.$http.post('/company/query', {
        page: {
          current: 1,
          pageSize: 10,
        },
      })
      this.companyList = res.result.records
      this.payList = res.result.records
    },
    //打开充值对话框，选择公司时保存id操作
    handleChange() {
      this.addPaymentForm.aid = this.selectCompanyKeys[this.selectCompanyKeys.length - 1]
    },
    handleChange2() {
      // this.queryParams.aid = this.selectPayKeys[this.selectPayKeys.length - 1]
      this.queryParams.aid = this.selectPayKeys
    },
    handleChange3() {
      this.addPaymentForm.topUpType = this.selectMoneyTypeKeys[this.selectMoneyTypeKeys.length - 1]
    },
    handleChange4() {
      console.log(this.selectMoneyTypeKeys2)
      this.queryParams.topUpName = this.selectMoneyTypeKeys2[this.selectMoneyTypeKeys2.length - 1]
    },
    //打开添加预付款对话框
    openPaymentDialog() {
      // this.getMoneyType()
      this.addPaymentForm.oid = parseInt(window.sessionStorage.getItem('usernameId'))
      this.addPaymentDialog = true
    },
    //充值确认操作
    addPaymentAction() {
      this.$refs.addPaymentFormRef.validate(async (valid) => {
        if (!valid) return
        this.addLoading = true
        this.addPaymentForm.aid = this.selectCompanyKeys
        const { data: data } = await this.$http.post('/company/getbyId', { id: this.addPaymentForm.aid })
        if (data.code !== 100) return this.$message.error('获取公司名称失败')
        this.addPaymentForm.operand = data.result.userName
        const { data: res } = await this.$http.post('/advancePayment/renewal', {
          oid: window.sessionStorage.getItem('usernameId'),
          aid: this.addPaymentForm.aid,
          amount: this.addPaymentForm.amount,
          topUpType: this.addPaymentForm.topUpType,
          remark: this.addPaymentForm.remark,
          operand: this.addPaymentForm.operand,
          imageAddress: this.imageAddress,
          street: this.addPaymentForm.street
        })
        if (res.code !== 100) {
          this.addLoading = false
          return this.$message.error(res.msg)
        }
        this.addLoading = false
        this.$message.success('充值成功')
        this.getPaymentList()
        this.addPaymentDialog = false
      })
    },
    //关闭添加对话框 清空内容
    closeAddDialog() {
      this.selectCompanyKeys = []
      this.previewPath = ''
      this.selectMoneyTypeKeys = []
      this.$refs.myUpload.clearFiles()
      this.$refs.addPaymentFormRef.resetFields()
    },
    // 查看图片
    async openSeepreviewPath(imageAddress) {
      this.seepreviewPath = imageAddress
      this.seepreviewVisible = true
    },
    //监听 pagesize改变事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getPaymentList()
    },
    //监听页码值改变事件
    handleCurrentChange(newPage) {
      this.queryInfo.current = newPage
      this.getPaymentList()
    },
    //处理图片预览效果
    handlePreview(file) {
      this.previewPath = file.response.result
      this.previewVisible = true
    },
    //处理图片移除的操作
    async handleRemove(file) {
      const { data: res } = await this.$http.post('/orders/upload', file)
      if (res.code !== 100) return this.$message.error(res.msg)
      this.$message.success('图片删除成功')
    },
    //监听图片上传成功的事件
    handleSuccess(response) {
      this.imageAddress = response.result
      console.log(response)
      this.$message.success(response.msg)
    },
    //el-input无法输入 修复bug操作
    change(e) {
      this.$forceUpdate()
    },
  },
}
