//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//



export default {
  data() {
    return {
      menuList: [
        { id: 100, authName: '首页', path: 'welcome' },
        { id: 125, authName: '账号管理', path: 'users' },
        { id: 146, authName: '公司管理', path: 'company' },
        { id: 102, authName: '地址管理', path: 'address' },
        { id: 105, authName: '车辆管理', path: 'car' },
        { id: 103, authName: '角色管理', path: 'roles' },
        { id: 101, authName: '订单管理', path: 'orders' },
        { id: 145, authName: '字典管理', path: 'category' },
        { id: 149, authName: '字标签管理', path: 'label' },
        { id: 147, authName: '预付款管理', path: 'payment' },
        { id: 148, authName: '操作日志', path: 'timeAxis' },
        // { id: 149, authName: '订单报表', path: 'orderReport' },
      ],
      //被激活链接地址
      activePath: '',
      icon: {
        100: 'el-icon-s-home',
        125: 'el-icon-user-solid',
        103: 'el-icon-copy-document',
        101: 'el-icon-shopping-bag-1',
        102: 'el-icon-tickets',
        145: 'el-icon-edit',
        146: 'el-icon-s-platform',
        147: 'el-icon-s-claim',
        148: 'el-icon-document',
        105: 'el-icon-link',
        149: 'el-icon-s-ticket'
      },
      isCollapse: false,
      roleId: '',
    }
  },
  created() {
    this.getMenuList()
    this.activePath = window.sessionStorage.getItem('activePath')
    this.getUserName()
  },
  methods: {
    // 判断登录用户什么身份 从而取后台菜单列表
    getMenuList() {
      this.roleId = window.sessionStorage.getItem('roleId')
      if (this.roleId == 0) {
        this.menuList = [
          { id: 100, authName: '首页', path: 'welcome' },
          { id: 125, authName: '账号管理', path: 'users' },
          { id: 146, authName: '公司管理', path: 'company' },
          { id: 102, authName: '地址管理', path: 'address' },
          { id: 105, authName: '车辆管理', path: 'car' },
          // { id: 103, authName: '角色管理', path: 'roles' },
          { id: 101, authName: '订单管理', path: 'orders' },
          { id: 145, authName: '字典管理', path: 'category' },
          { id: 149, authName: '标签管理', path: 'label' },
          { id: 147, authName: '预付款管理', path: 'payment' },
          { id: 148, authName: '操作日志', path: 'timeAxis' },
          // { id: 149, authName: '订单报表', path: 'orderReport' },
        ]
      } else if (this.roleId == 3) {
        this.menuList = [
          { id: 100, authName: '首页', path: 'welcome' },
          { id: 147, authName: '预付款管理', path: 'payment' },
        ]
      } else if (this.roleId == 5) {
        this.menuList = [
          { id: 100, authName: '首页', path: 'welcome' },
          { id: 125, authName: '账号管理', path: 'users' },
          { id: 146, authName: '公司管理', path: 'company' },
          { id: 102, authName: '地址管理', path: 'address' },
          { id: 105, authName: '车辆管理', path: 'car' },
          { id: 101, authName: '订单管理', path: 'orders' },
          // { id: 149, authName: '标签管理', path: 'label' },
        ]
      } else {
        window.sessionStorage.clear()
        this.$router.push('/Login')
        this.$message.error('没有登录权限，请联系管理员')
      }
      // console.log(this.classId)
    },
    // 控制侧边栏是否收起
    topButton() {
      this.isCollapse = !this.isCollapse
    },
    //获取登录时传来的username
    getUserName() {
      this.$store.state.username = window.sessionStorage.getItem('username')
    },
    logout() {
      window.sessionStorage.clear()
      this.$router.push('/Login')
      this.$message.success('退出成功')
    },
    // async getMenuList() {
    //   //   const { data: res } = await this.$http.get('/menus')
    //   //   if (res.meta.status !== 200) return this.$message.error('获取列表失败')
    //   //   console.log(res.data)
    //   //   this.menuList = res.data
    // },
    //保存链接的激活状态
    saveNavStatus(activePath) {
      window.sessionStorage.setItem('activePath', activePath)
      this.activePath = activePath
    },
    //收缩展开侧边栏
    topButton() {
      this.isCollapse = !this.isCollapse
    },
  },
}
