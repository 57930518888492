//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import cityData from '../Company/citydata.js'
import moment from "moment"
export default {
  data() {
    return {
      userList: [],
      queryInfo: {
        current: 1,
        pageSize: 50,
      },
      queryInfo1: {
        current1: 1,
        pageSize1: 9999,
      },
      total1: 0,
      total: 0,
      addUserDialog: false,
      editUserDialog: false,
      addUserForm: {
        name: '',
        password: '',
        avatar: '',
        isValid: 1,
      },
      addUserFormRules: {
        name: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          // { pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/, message: '6-18位,含有字母和数字' },
        ],
        avatar: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          // { pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/, message: '6-18位,含有字母和数字' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { pattern: /^(?=.*[a-z])(?=.*\d)[^]{6,18}$/, message: '6-18位,含有字母和数字' },
        ],
        // companyId:[
        //   { type:'array',required: true, message: '请选择公司', trigger: 'change' }
        // ]
      },
      editUserForm: {
        password: '',
      },
      editUserFormRules: {
        // name: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { pattern: /^(?=.*[a-z])(?=.*\d)[^]{6,18}$/, message: '6-18位,含有字母和数字' },
        ],
      },
      editInformationForm: {},
      editInformationFormRules: {
        // name: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
      },
      //查询对象
      queryParams: {
        name: undefined,
        userName: undefined,
        avatar: undefined,
        phone: undefined,
        classId: undefined,
        roleId: undefined,
        time: undefined,
      },
      editInformationDialog: false,
      //选择身份
      //级联选择器双向绑定到的数组
      selectUserKeys: [],
      userTypeList: [
        {
          value: 0,
          label: '超级管理员',
        },
        {
          value: 1,
          label: '企业',
        },
        {
          value: 2,
          label: '清运人',
        },
        {
          value: 3,
          label: '会计',
        },
        {
          value: 4,
          label: '运营',
        },
      ],
      //级联选择器双向绑定到的数组
      selectCompanyKeys: [],
      companyList: [],
      //级联选择框配置对象
      companyProps: {
        value: 'id',
        label: 'userName',
        children: 'categoryList',
      },
      //级联选择器双向绑定到的数组
      selectRoleIdKeys: [],
      roleIdList: [],
      //级联选择框配置对象
      roleIdProps: {
        value: 'id',
        label: 'roleName',
        children: 'categoryList',
      },
      standby: '',
      removeList: {},
      //级联选择器双向绑定到的数组
      selectUser2Keys: [],
      //级联选择器双向绑定到的数组
      selectCompany2Keys: [],
      selectRoleId2Keys: [],
      loading: true,
      isShow: true,
      //删除loading
      deleteLoading: false,
      //添加loading
      addLoading: false,
      addpassLoading: false,
      editLoading: false,
      // 查看子用户
      seeSonUserDialog: false,
      totalSonUserList: [],
      // 添加子用户
      addSonUserDialog: false,
      addSonUserForm: {},
      addSonUserFormRules: {},
      selectSonUserKeys: [],
      sonUserList: [],
      sonUserProps: {
        value: 'id',
        label: 'name',
      },
      // 取消审核
      cancelUserAuditList: [],
      // 审核
      userAuditList: [],
      // 绑定公司
      totalCompanyList: [],
      bindCompanyDialog: false,
      bindCompanyLoading: true,
      companyKeyList: [],
      userId: '',
      // 搜索
      queryParams1: {},
      // 是否显示选择身份
      special: 0,
      true: '',
      companyKeyList2: [],
      licencePlateClassId: '',
      licencePlateClassId2: '',
      filterList: [
        { text: '超级管理员(内)', value: '0' },
        { text: '企业(内)', value: '1' },
        { text: '清运人(内)', value: '2' },
        { text: '会计(内)', value: '3' },
        { text: '运营(内)', value: '4' },
        { text: '企业(外)', value: '5' },
      ],
      streetKey: [],
      // 地址选择
      cityData,
      userTypeList2: [
        {
          value: 0,
          label: '超级管理员(内)',
        },
        {
          value: 1,
          label: '企业(内)',
        },
        {
          value: 2,
          label: '清运人(内)',
        },
        {
          value: 3,
          label: '会计(内)',
        },
        {
          value: 4,
          label: '运营(内)',
        },
        {
          value: 5,
          label: '企业(外)',
        },
      ],
      selectUserKeys2: [],
      totaluserList: [],
      isshowTable: false,
      finishTime: undefined, //结束时间
      beginTime: undefined, //开始时间
      changeCompanyLoading: false,
      // 选择导出数据数组
      daoChuChoseList: [],
    }
  },
  created() {
    this.getUserList()
    this.getCompanyList()
    this.getRolesList()
  },
  methods: {
    dateFormat(row, column) {
      let date = row[column.property]
      return moment(date).format('YYYY-MM-DD')
    },
    // table选择框
    handleSelectionChange2(val) {
      this.daoChuChoseList = val
      console.log(this.daoChuChoseList)
    },
    // input方法
    remoteMethod(query) {
      if (query !== '') {
        this.changeCompanyLoading = true
        setTimeout(() => {
          this.selectInput(query)
        }, 200)
      } else {
        this.getCompanyList()
        // this.conpanyList = []
      }
    },
    // 查询数据
    async selectInput(query) {
      const { data: res } = await this.$http.post('/company/query', {
        data: {
          userName: query,
        },
        page: {
          current: 1,
          pageSize: 10,
        },
      })
      this.companyList = res.result.records
      this.changeCompanyLoading = false
    },
    daochuAction() {
      // console.log(this.daoChuChoseList)
      if (this.daoChuChoseList.length == 0) {
        this.gettotalUserList()
      } else {
        this.choseDaoChuAction()
      }
    },
    choseDaoChuAction() {
      this.loading = true
      this.totaluserList = this.daoChuChoseList
      setTimeout(() => {
        this.loading = false
        this.$refs.table.clearSelection();
        this.exportExcel('unit', '人员名单')
      }, 1000);
    },
    // 获取所有账号
    async gettotalUserList() {
      this.loading = true
      const { data: res } = await this.$http.post('/admin/user/userInDetail', {
        page: {
          current: 1,
          pageSize: 99999,
        },
        data: {
          name: this.queryParams.name,
          avatar: this.queryParams.avatar,
          phone: this.queryParams.phone,
          userName: this.queryParams.userName,
          classId: this.queryParams.classId,
          roleId: this.queryParams.roleId,
          beginTime: this.beginTime,
          finishTime: this.finishTime,
        },
      })
      if (res.code !== 100) return this.$message.error('获取失败')
      this.totaluserList = res.result.records
      setTimeout(() => {
        this.loading = false
        this.exportExcel('unit', '人员名单')
      }, 1000);
    },
    handleChangeClass() {
      if (this.selectUserKeys2[this.selectUserKeys2.length - 1] == '5') {
        this.queryParams.classId = 1
        this.queryParams.roleId = undefined
      } else {
        this.queryParams.roleId = this.selectUserKeys2[this.selectUserKeys2.length - 1]
        this.queryParams.classId = undefined
      }
    },
    // 编辑公司 选择街道
    changeStreet() {
      this.queryParams1.street = this.streetKey[this.streetKey.length - 1]
    },
    //导出功能
    // exportExcel(name, id) {
    //   var wb = XLSX.utils.table_to_book(document.querySelector('#' + id))
    //   var wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' })
    //   try {
    //     FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), name + '.xlsx')
    //   } catch (e) { if (typeof console !== 'undefined') console.log(e, wbout) }
    //   return wbout
    // },
    // 第二种导出功能
    exportExcel(elementName, fileName) {
      const wb = XLSX.utils.table_to_book(this.clearHead(elementName), { raw: true });
      const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' });
      FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
    },
    // 导出时，去除展开和操作列
    clearHead(elementName) {
      const tableDom = document.querySelector('#' + elementName).cloneNode(true);
      const tableHeader = tableDom.querySelector('.el-table__header-wrapper');
      const tableBody = tableDom.querySelector('.el-table__body');
      tableHeader.childNodes[0].append(tableBody.childNodes[1]);
      const headerDom = tableHeader.childNodes[0].querySelectorAll('th');
      for (const key in headerDom) {
        if (headerDom[key].innerText === '操作' || headerDom[key].innerText === '展开') {
          headerDom[key].remove();
        }
      }
      // 清理掉checkbox 和操作的button
      const tableList = tableHeader.childNodes[0].childNodes[2].querySelectorAll('td');
      for (let key = 0; key < tableList.length; key++) {
        if (tableList[key].querySelectorAll('.el-button').length > 0) {
          tableList[key].remove();
        }
      }
      return tableHeader;
    },
    // 筛选
    filterTag(value, row) {
      if (value == '5') {
        return row.classId == 1
      } else {
        return row.roleId == value
      }

    },
    // 添加用户 输入用户名失焦时 判断是否重名
    async onInputBlur() {
      const { data: res } = await this.$http.post('/admin/isExist', {
        name: this.addUserForm.name,
      })
      if (res.code !== 100) {
        this.true = false
        return this.$message.error('账户名重复')
      }
      this.true = true
    },
    getRowKeys(row) {
      return row.id
    },
    tableCellClassName({ row, column, rowIndex, columnIndex }) {
      row.index = rowIndex
      column.index = columnIndex
    },
    // 获取位置
    cellClick(row, column, cell, event) {
      this.rowIndex = row.index
    },
    // 公司解绑
    async unbundleAction(row) {
      const confirmResult = await this.$confirm('此操作将永久解绑该公司, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).catch((err) => err)
      if (confirmResult !== 'confirm') return this.$message.info('取消了删除')
      const { data: res } = await this.$http.post('/admin/unbundle', {
        companyId: row.children[this.rowIndex].id,
        id: row.id,
      })
      if (res.code !== 100) return this.$message.error(res.msg)
      this.$message.success('解绑成功')
      let $table = this.$refs.table
      this.userList.map((item) => {
        $table.toggleRowExpansion(item, false)
      })
    },
    // 打开展开行 请求公司下信息
    async toogleExpand(row,) {
      const { data: res } = await this.$http.post('/company/findCompany', { id: row.id })
      if (res.code !== 100) {
        this.seeUserLoading = false
        return this.$message.error(res.msg)
      }
      row.children = res.result
      let $table = this.$refs.table
      /* this.userList.map((item) => {
        $table.toggleRowExpansion(item, false)
      }) */
      $table.toggleRowExpansion(row)
    },
    // 绑定公司搜索按钮
    async handleQuery1() {
      this.bindCompanyLoading = true
      const { data: res } = await this.$http.post('/company/query', {
        data: {
          userName: this.queryParams1.userName,
          principal: this.queryParams1.principal,
          street: this.queryParams1.street,
        },
        page: {
          current: 1,
          pageSize: 9999,
        },
      })
      if (res.code !== 100) {
        this.bindCompanyLoading = false
        return this.$message.error(res.msg)
      }
      this.totalCompanyList = res.result.records

      this.bindCompanyLoading = false
    },
    // 重置按钮
    resetQuery1() {
      this.queryParams1 = {}
      this.streetKey = []
      this.totalCompanyList = []
      this.companyKeyList2 = []
    },
    // 获取公司列表
    async getbindCompanyList() {
      const { data: res } = await this.$http.post('/company/query', {
        page: {
          current: this.queryInfo1.current1,
          pageSize: this.queryInfo1.pageSize1,
        },
      })
      if (res.code !== 100) {
        this.bindCompanyLoading = false
        return this.$message.error(res.msg)
      }
      this.bindCompanyLoading = false
      this.totalCompanyList = res.result.records
      this.total1 = res.result.total
      for (let i = 0; i < this.totalCompanyList.length; i++) {
        for (let j = 0; j < this.companyKeyList.length; j++) {
          if (this.totalCompanyList[i].id == this.companyKeyList[j].id) {
            this.totalCompanyList.splice(i, 1)
          }
        }
      }
    },
    // 打开绑定公司对话框
    async openBindCompanyDialog(id) {
      this.userId = id
      const { data: res } = await this.$http.post('/company/findCompany', { id: id })
      if (res.code !== 100) return this.$message.error(res.msg)
      this.companyKeyList = res.result
      this.bindCompanyLoading = false
      this.bindCompanyDialog = true
    },
    // checkbox选择框操作
    handleSelectionChange(val) {
      this.companyKeyList2 = val
    },
    // 绑定确认操作
    async bindCompanyAciton() {
      for (let i = 0; i < this.companyKeyList2.length; i++) {
        const { data: res } = await this.$http.post('/admin/binding', {
          id: this.userId,
          companyId: this.companyKeyList2[i].id,
        })
        if (res.code !== 100) return this.$message.error(res.msg)
      }
      this.$message.success('绑定公司成功')
      this.queryParams1 = {}
      this.totalCompanyList = []
      this.streetKey = []
      let $table = this.$refs.table
      this.userList.map((item) => {
        $table.toggleRowExpansion(item, false)
      })
    },
    // 关闭绑定公司对话框
    closeBindCompanyDialog() {
      this.queryParams1 = {}
      this.totalCompanyList = []
      this.bindCompanyDialog = false
    },
    //搜索按钮
    async handleQuery() {
      if (this.queryParams.time !== undefined && this.queryParams.time !== null) {
        this.beginTime = this.queryParams.time[0]
        this.finishTime = this.queryParams.time[1]
      } else {
        this.beginTime = undefined
        this.finishTime = undefined
      }
      this.loading = true
      const { data: res } = await this.$http.post('/admin/user/userInDetail', {
        data: {
          name: this.queryParams.name,
          avatar: this.queryParams.avatar,
          phone: this.queryParams.phone,
          userName: this.queryParams.userName,
          classId: this.queryParams.classId,
          roleId: this.queryParams.roleId,
          beginTime: this.beginTime,
          finishTime: this.finishTime,
        },
        page: {
          current: this.queryInfo.current,
          pageSize: this.queryInfo.pageSize,
        },
      })
      if (res.code !== 100) {
        this.loading = false
        return this.$message.error(res.msg)
      }
      this.loading = false
      let $table = this.$refs.table
      this.userList.map((item) => {
        $table.toggleRowExpansion(item, false)
      })
      this.userList = res.result.records
      this.total = res.result.total

    },
    //重置按钮
    resetQuery() {
      this.loading = true
      this.$refs.queryForm.resetFields()
      this.beginTime = undefined
      this.finishTime = undefined
      this.queryInfo.current = 1
      this.queryInfo.pageSize = 50
      this.selectUserKeys2 = []
      this.queryParams = {}
      this.$refs.table.clearSelection();
      let $table = this.$refs.table
      this.userList.map((item) => {
        $table.toggleRowExpansion(item, false)
      })
      this.getUserList()
    },
    //获取分类
    async getRolesList() {
      const { data: res } = await this.$http.post('/role/inquire', {
        page: {
          current: this.queryInfo.current,
          pageSize: this.queryInfo.pageSize,
        },
      })
      if (res.code !== 100) return this.$message.error('获取字典失败')
      this.roleIdList = res.result
    },
    //获取公司名称
    async getCompanyList() {
      const { data: res } = await this.$http.post('/company/query', {
        page: {
          current: 1,
          pageSize: 10,
        },
      })
      if (res.code !== 100) return this.$message.error('获取公司列表失败')
      this.companyList = res.result.records
    },
    //选择角色
    handleChange3() {
      this.addUserForm.roleId = this.selectRoleIdKeys[this.selectRoleIdKeys.length - 1]
    },
    //选择公司
    async handleChange2() {
      this.addUserForm.companyId = this.selectCompanyKeys[this.selectCompanyKeys.length - 1]
      const { data: res } = await this.$http.post('/company/getbyId', { id: this.addUserForm.companyId })
      if (res.code !== 100) return this.$message.error(res.msg)
      this.special = res.result.special
    },
    //选择身份
    handleChange() {
      this.addUserForm.roleId = this.selectUserKeys[this.selectUserKeys.length - 1]
      this.licencePlateClassId = this.selectUserKeys[this.selectUserKeys.length - 1]
    },
    //选择身份
    handleChange4() {
      this.editInformationForm.roleId = this.selectUser2Keys[this.selectUser2Keys.length - 1]
    },
    //选择公司
    handleChange5() {
      this.editInformationForm.companyId = this.selectCompany2Keys[this.selectCompany2Keys.length - 1]
    },
    handleChange6() {
      this.editInformationForm.roleId = this.selectRoleId2Keys[this.selectRoleId2Keys.length - 1]
    },
    //获取用户列表
    async getUserList() {
      this.loading = true
      const { data: res } = await this.$http.post('/admin/user/userInDetail', {
        page: {
          current: this.queryInfo.current,
          pageSize: this.queryInfo.pageSize,
        },
        data: {
          name: this.queryParams.name,
          avatar: this.queryParams.avatar,
          phone: this.queryParams.phone,
          userName: this.queryParams.userName,
          classId: this.queryParams.classId,
          roleId: this.queryParams.roleId,
          beginTime: this.beginTime,
          finishTime: this.finishTime
        }
      })
      if (res.code !== 100) return this.$message.error('获取失败')
      this.userList = res.result.records
      this.total = res.result.total
      this.sonUserList = res.result.records
      this.loading = false
      for (let i = 0; i < this.userList.length; i++) {
        this.userList[i].children = []
      }
    },
    //打开添加用户弹窗
    openUserDialog() {
      this.addUserDialog = true
    },
    //添加用户确定操作
    addUserAction() {
      this.$refs.addUserFormRef.validate(async (valid) => {
        if (!valid) return
        this.addLoading = true
        this.addUserForm.companyId = this.selectCompanyKeys
        // 提交表单时 判断用户名是否重复
        // console.log(this.addUserForm)
        const { data: data } = await this.$http.post('/admin/isExist', {
          name: this.addUserForm.name,
        })
        if (data.code !== 100) {
          this.true = false
          this.addLoading = false
          return this.$message.error('账户名重复')
        }
        this.true = true

        const { data: res } = await this.$http.post('/admin/user/adminUpdate', this.addUserForm)
        if (res.code !== 100) {
          this.addLoading = false
          return this.$message.error(res.msg)
        }
        this.addLoading = false
        this.$message.success('添加用户成功')
        this.getUserList()
        this.addUserDialog = false
        this.getCompanyList()
      })
    },
    //打开编辑信息对话框
    async openEditDialog(id) {
      const { data: res } = await this.$http.post('/admin/user/detail', { id: id })
      if (res.code !== 100) return this.$message.error('获取用户信息失败')
      this.editInformationForm = res.result
      this.selectUser2Keys = [res.result.roleId]
      // console.log(this.selectUser2Keys)
      this.licencePlateClassId2 = res.result.roleId
      this.selectCompany2Keys = res.result.companyId
      this.selectRoleId2Keys = res.result.roleId
      this.editInformationDialog = true
    },
    //打开修改密码弹窗
    async openEditPasswordDialog(id) {
      const { data: res } = await this.$http.post('/admin/user/detail', { id: id })
      if (res.code !== 100) return this.$message.error('查询用户失败')
      this.editUserForm = res.result
      this.editUserForm.password = ''
      this.editUserDialog = true
    },
    //确定提交修改密码信息
    editUserAction() {
      this.$refs.editUserFormRef.validate(async (valid) => {
        if (!valid) return
        this.addpassLoading = true
        const { data: res } = await this.$http.post('/admin/user/adminUpdate', this.editUserForm)
        if (res.code !== 100) {
          this.addpassLoading = false
          return this.$message.error(res.msg)
        }
        this.addpassLoading = false
        this.$message.success('修改密码成功')
        this.getUserList()
        this.editUserDialog = false
      })
    },
    //修改用户信息确认操作
    editInformationAction() {
      this.$refs.editInformationFormRef.validate(async (valid) => {
        if (!valid) return
        this.editLoading = true
        const { data: res } = await this.$http.post('/admin/user/adminUpdate', this.editInformationForm)
        if (res.code !== 100) {
          this.editLoading = false
          return this.$message.error(res.msg)
        }
        this.editLoading = false
        this.$message.success('修改用户信息成功')
        this.getUserList()
        this.editInformationDialog = false
      })
    },
    //删除用户
    async deleteUser(id) {
      const confirmResult = await this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).catch((err) => err)
      if (confirmResult !== 'confirm') return this.$message.info('取消了删除')
      this.deleteLoading = true
      //id查询
      const { data: data } = await this.$http.post('/admin/user/detail', { id: id })
      if (data.code !== 100) return this.$message.error('查询用户失败')
      this.removeList = data.result

      //删除请求操作
      const { data: res } = await this.$http.post('/admin/user/delAdmin', { ids: [this.removeList.id], standby: this.removeList.name })
      if (res.code !== 100) {
        this.deleteLoading = false
        return this.$message.error(res.msg)
      }
      this.deleteLoading = false
      this.$message.success('用户删除成功')
      this.getUserList()
    },
    //关闭添加用户框
    //清除所填信息
    closeDialog() {
      this.true = ''
      this.selectCompanyKeys = []
      this.selectUserKeys = []
      this.licencePlateClassId = ''
      this.getCompanyList()
      this.$refs.addUserFormRef.resetFields()
    },
    //关闭修改用户界面，清空所填内容
    closeEditUserDialog() {
      this.selectUser2Keys = []
      this.$refs.editUserFormRef.resetFields()
    },
    closeEditInformationDialog() {
      this.selectUser2Keys = []
      this.$refs.editInformationFormRef.resetFields()
    },
    // 打开查看子用户对话框
    openSeeSonUserDialog() {
      this.seeSonUserDialog = true
    },
    // 关闭查看子用户对话框
    closeSeeSonUserDialog() {
      this.seeSonUserDialog = false
    },
    // 打开添加子用户对话框
    openAddSonUserDialog() {
      this.addSonUserDialog = true
    },
    // 添加子用户 选择用户操作
    handleChangeSonUser() { },
    // 关闭添加子用户对话框
    closeAddSonUserDialog() {
      this.addSonUserDialog = false
    },
    //监听 pagesize改变事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getUserList()
    },
    //监听页码值改变事件
    handleCurrentChange(newPage) {
      this.queryInfo.current = newPage
      this.getUserList()
    },
    //是否显示搜索栏
    isShowAciton() {
      this.isShow = !this.isShow
    },
    // 刷新数据
    resetAciton() {
      this.loading = true
      this.queryParams = {}
      this.getUserList()
      let $table = this.$refs.table
      this.userList.map((item) => {
        $table.toggleRowExpansion(item, false)
      })
    },
    // 遍历
    forArr(arr, isExpand) {
      arr.forEach((i) => {
        // toggleRowExpansion见element文档
        this.$refs.table.toggleRowExpansion(i, isExpand)
        if (i.children) {
          this.forArr(i.children, isExpand)
        }
      })
      this.getUserList()
    },
    // 取消审核操作
    async cancelUserAudit(id) {
      // 查询数据
      const { data: res } = await this.$http.post('/admin/user/detail', { id: id })
      if (res.code !== 100) return this.$message.error(res.msg)
      this.cancelUserAuditList = res.result
      this.cancelUserAuditList.isAudit = 0
      // 修改
      const { data: data } = await this.$http.post('/admin/user/adminUpdate', this.cancelUserAuditList)
      if (data.code !== 100) return this.$message.error(data.msg)
      this.$message.success('操作成功')
      this.getUserList()
    },
    // 审核操作
    async userAudit(id) {
      const { data: res } = await this.$http.post('/admin/user/detail', { id: id })
      if (res.code !== 100) return this.$message.error(res.msg)
      this.userAuditList = res.result
      this.userAuditList.isAudit = 1
      // 修改
      const { data: data } = await this.$http.post('/admin/user/adminUpdate', this.userAuditList)
      if (data.code !== 100) return this.$message.error(data.msg)
      this.$message.success('操作成功')
      this.getUserList()
    },
    //el-input无法输入 修复bug操作
    change(e) {
      this.$forceUpdate()
    },
    // 隐藏箭头
    getRowClass() {
      return 'row-expand-cover'
    },
  },
}
