//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cityData from './citydata'
import * as XLSX from 'xlsx'
import FileSaver from 'file-saver'
export default {
  data() {
    return {
      //地址列表，模糊查询，必须传的参数
      addressList: [],
      searchVal: '',
      queryInfo: {
        current: 1,
        pageSize: 50,
      },
      queryInfo1: {
        current: 1,
        pageSize: 9999,
      },
      total: 0,
      userList: [],
      //级联选择框配置对象
      userProps: {
        value: 'id',
        label: 'userName',
      },
      //级联选择器双向绑定到的数组
      selectUserKeys: [],
      type2List: [],
      typeList: [],
      //级联选择框配置对象
      typeProps: {
        value: 'id',
        label: 'name',
        children: 'categoryList',
      },
      //级联选择器双向绑定到的数组
      selectTypeKeys: [],
      //添加地址的表单，验证规则
      addAddressDialog: false,
      addAddressForm: {
        fid: '',
        linkman: '',
        tel: '',
        cid: '',
        address: '',
      },
      addAddressFormRules: {
        linkman: [{ required: true, message: '请输入联系人姓名', trigger: 'blur' }],
        address: [{ required: true, message: '请输入地址', trigger: 'blur' }],
        tel: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          {
            pattern: /^1[34578]\d{9}$/,
            message: '目前只支持中国大陆的手机号码',
          },
        ],
        selectEditUserKeys: [
          {
            type: 'array',
            required: true,
            message: '请选择所属用户',
            trigger: 'change',
          },
        ],
      },
      //编辑地址的表单，验证规则
      editAddressDialog: false,
      editAddressForm: {
        fid: '',
        linkman: '',
        tel: '',
        cid: '',
        address: '',
      },
      editAddressFormRules: {
        linkman: [{ required: true, message: '请输入联系人姓名', trigger: 'blur' }],
        address: [{ required: true, message: '请输入地址', trigger: 'blur' }],
        tel: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          {
            pattern: /^1[34578]\d{9}$/,
            message: '目前只支持中国大陆的手机号码',
          },
        ],
      },
      selectEditUserKeys: [],
      selectEditTypeKeys: [],
      //查询对象
      queryParams: {},
      removeList: {},
      selectUserNameKeys: [],
      userNameList: [],
      userNameProps: {
        value: 'id',
        label: 'userName',
      },
      loading: true,
      isShow: true,
      //删除loading
      deleteLoading: false,
      addLoading: false,
      editLoading: false,
      // 地址选择
      cityData,
      streetKey: [],
      streetKey2: [],
      totalAddress: [],
      isshowTable: false,
      changeCompanyLoading: false,
      // 选择导出数据数组
      daoChuChoseList: [],
    }
  },
  created() {
    //加载页面时，获取地址列表
    this.getAddressList()
    this.getUserList()
    this.getTypeList()
    this.getUserNameList()
  },
  methods: {
    // table选择框
    handleSelectionChange(val) {
      this.daoChuChoseList = val
      console.log(this.daoChuChoseList)
    },
    // input方法
    remoteMethod(query) {
      if (query !== '') {
        this.changeCompanyLoading = true
        setTimeout(() => {
          this.selectInput(query)
        }, 200)
      } else {
        this.getUserList()
        // this.conpanyList = []
      }
    },
    remoteMethod2(query) {
      if (query !== '') {
        this.changeCompanyLoading = true
        setTimeout(() => {
          this.selectInput2(query)
        }, 200)
      } else {
        this.getUserNameList()
        // this.conpanyList = []
      }
    },
    remoteMethod3(query) {
      if (query !== '') {
        this.changeCompanyLoading = true
        setTimeout(() => {
          this.selectInput(query)
        }, 200)
      } else {
        this.getUserList()
        // this.conpanyList = []
      }
    },
    // 查询数据
    async selectInput(query) {
      const { data: res } = await this.$http.post('/company/query', {
        data: {
          userName: query,
        },
        page: {
          current: 1,
          pageSize: 10,
        },
      })
      this.userList = res.result.records
      this.changeCompanyLoading = false
    },
    async selectInput2(query) {
      const { data: res } = await this.$http.post('/company/query', {
        data: {
          userName: query,
        },
        page: {
          current: 1,
          pageSize: 10,
        },
      })
      this.userNameList = res.result.records
      this.changeCompanyLoading = false
    },
    // 第二种导出功能
    exportExcel(elementName, fileName) {
      const wb = XLSX.utils.table_to_book(this.clearHead(elementName), { raw: true });
      const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' });
      FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
    },
    // 导出时，去除展开和操作列
    clearHead(elementName) {
      const tableDom = document.querySelector('#' + elementName).cloneNode(true);
      const tableHeader = tableDom.querySelector('.el-table__header-wrapper');
      const tableBody = tableDom.querySelector('.el-table__body');
      tableHeader.childNodes[0].append(tableBody.childNodes[1]);
      const headerDom = tableHeader.childNodes[0].querySelectorAll('th');
      for (const key in headerDom) {
        if (headerDom[key].innerText === '操作' || headerDom[key].innerText === '查看') {
          headerDom[key].remove();
        }
      }
      // 清理掉checkbox 和操作的button
      const tableList = tableHeader.childNodes[0].childNodes[2].querySelectorAll('td');
      for (let key = 0; key < tableList.length; key++) {
        if (tableList[key].querySelectorAll('.el-button').length > 0) {
          tableList[key].remove();
        }
      }
      return tableHeader;
    },
    daochuAction() {
      if (this.daoChuChoseList.length == 0) {
        this.getTotalAddressList()
      } else {
        this.choseDaoChuAction()
      }
    },
    choseDaoChuAction() {
      this.loading = true
      this.totalAddress = this.daoChuChoseList
      setTimeout(() => {
        this.loading = false
        this.$refs.multipleTable.clearSelection();
        this.exportExcel('educe-table', '地址名单')
      }, 1000);
    },
    // 获取所有地址列表
    async getTotalAddressList() {
      this.loading = true
      const { data: res } = await this.$http.post('/address/inquire', {
        page: {
          current: 1,
          pageSize: 99999,
        },
        data: {
          address: this.queryParams.address,
          fid: this.queryParams.fid,
          isValid: 1,
        },
      })
      if (res.code !== 100) return this.$message.error('获取地址列表失败')
      this.totalAddress = res.result.records
      setTimeout(() => {
        this.loading = false
        this.exportExcel('educe-table', '地址名单')
      }, 1000);
    },
    // 选择区域 新增地址
    changeStreet() {
      this.addAddressForm.district = this.streetKey[this.streetKey.length - 1]
    },
    // 选择区域 编辑地址
    changeStreet2() {
      this.editAddressForm.district = this.streetKey2[this.streetKey2.length - 1]
    },
    //获取所属用户列表
    async getUserNameList() {
      const { data: res } = await this.$http.post('/company/query', {
        page: {
          current: 1,
          pageSize: 10,
        },
      })
      if (res.code !== 100) return this.$message.error('获取用户列表失败')
      this.userNameList = res.result.records
    },
    //搜索按钮
    async handleQuery() {
      this.loading = true
      this.queryParams.fid = this.selectUserNameKeys
      const { data: res } = await this.$http.post('/address/inquire', {
        data: {
          address: this.queryParams.address,
          fid: this.queryParams.fid,
          isValid: 1,
        },
        page: {
          current: this.queryInfo.current,
          pageSize: this.queryInfo.pageSize,
        },
      })
      if (res.code !== 100) {
        this.loading = false
        return this.$message.error(res.msg)
      }
      this.loading = false
      this.getUserNameList()
      this.addressList = res.result.records
      this.total = res.result.total
    },
    //重置按钮
    resetQuery() {
      this.loading = true
      this.getUserNameList()
      this.$refs.queryForm.resetFields()
      this.$refs.multipleTable.clearSelection();
      this.selectUserNameKeys = []
      this.getAddressList()
    },
    //获取用户列表
    async getUserList() {
      const { data: res } = await this.$http.post('/company/query', {
        page: {
          current: 1,
          pageSize: 10,
        },
      })
      if (res.code !== 100) return this.$message.error('查询用户失败')
      this.userList = res.result.records
    },
    //获取分类数据
    async getTypeList() {
      const { data: res } = await this.$http.post('/caterory/query', {
        page: {
          current: this.queryInfo.current,
          pageSize: this.queryInfo.pageSize,
        },
      })
      if (res.code !== 100) return this.$message.error('查询分类列表失败')
      this.typeList = res.result.records.filter((item) => item.id == 20)
      this.type2List = this.typeList[0].categoryList
    },
    //级联选择框选中选项，触发此函数
    //添加地址 选择用户
    handleChange() {
      this.addAddressForm.fid = this.selectUserKeys[this.selectUserKeys.length - 1]
    },
    //级联选择框选中选项，触发此函数
    //添加地址 选择地址类型
    handleChange2() {
      this.addAddressForm.cid = this.selectTypeKeys[this.selectTypeKeys.length - 1]
    },
    //编辑地址 选择用户
    handleChange3() {
      this.editAddressForm.fid = this.selectEditUserKeys[this.selectEditUserKeys.length - 1]
    },
    //编辑地址 选择地址类型
    handleChange4() {
      this.editAddressForm.cid = this.selectEditTypeKeys[this.selectEditTypeKeys.length - 1]
    },
    //搜索 所属用户
    handleChange5() {
      this.queryParams.fid = this.selectUserNameKeys[this.selectUserNameKeys.length - 1]
    },
    //获取地址列表
    async getAddressList() {
      this.loading = true
      const { data: res } = await this.$http.post('/address/inquire', {
        page: {
          current: this.queryInfo.current,
          pageSize: this.queryInfo.pageSize,
        },
        data: {
          isValid: 1,
        },
      })
      if (res.code !== 100) return this.$message.error('获取地址列表失败')
      this.addressList = res.result.records
      this.total = res.result.total
      this.loading = false
    },
    //打开添加地址对话框
    openAddressDialog() {
      this.addAddressDialog = true
    },
    //确定添加地址操作
    addAddressAction() {
      this.$refs.addAddressFormRef.validate(async (valid) => {
        if (!valid) return
        this.addLoading = true
        this.addAddressForm.fid = this.selectUserKeys
        // console.log(this.addAddressForm)
        const { data: res } = await this.$http.post('/address/update', this.addAddressForm)
        if (res.code !== 100) {
          this.addLoading = false
          return this.$message.error(res.msg)
        }
        this.addLoading = false
        this.$message.success('添加地址成功')
        this.addAddressForm = []
        this.getAddressList()
        this.getUserList()
        this.addAddressDialog = false
      })
    },
    //打开修改地址对话框
    async openEditDialog(id) {
      const { data: res } = await this.$http.post('/address/getById', { id: id })
      if (res.code !== 100) return this.$message.error('查询失败')
      this.editAddressForm = res.result
      this.streetKey2 = res.result.district
      this.getCompanyId(res.result.fid)
      this.editAddressDialog = true
      // this.getUserById()
      this.getTypeById()
    },
    async getCompanyId(id) {
      const { data: res } = await this.$http.post('/company/getbyId', { id: id })
      this.userList = []
      this.userList.push(res.result)
      this.selectEditUserKeys = res.result.userName
    },
    //打开修改地址对话框查询用户信息
    async getUserById() {
      const { data: res } = await this.$http.post('/admin/user/detail', { id: this.editAddressForm.id })
      if (res.code !== 100) return this.$message.error('查询用户失败')
      this.selectEditUserKeys = res.result.id
    },
    //打开修改地址对话框 查询地址类型
    async getTypeById() {
      const { data: res } = await this.$http.post('/caterory/getById', { id: this.editAddressForm.cid })
      if (res.code !== 100) return this, $message.error('查询地址类型失败')
      this.selectEditTypeKeys = res.result.id
    },
    //修改地址操作
    editAddressAction() {
      this.$refs.editAddressFormRef.validate(async (valid) => {
        if (!valid) return
        this.editLoading = true
        // this.editAddressForm.fid = this.selectEditUserKeys
        const { data: res2 } = await this.$http.post('/company/query', {
          data: {
            userName: this.selectEditUserKeys,
          },
          page: {
            current: 1,
            pageSize: 10,
          },
        })
        if (res2.result.records.length == 0) {
          this.editAddressForm.fid = this.selectEditUserKeys
        } else {
          this.editAddressForm.fid = res2.result.records[0].id
        }
        const { data: res } = await this.$http.post('/address/update', this.editAddressForm)
        if (res.code !== 100) {
          this.editLoading = false
          return this.$message.error(res.msg)
        }
        this.editLoading = false
        this.$message.success('修改地址成功')
        this.editAddressDialog = false
        this.getAddressList()
      })
    },
    //删除地址操作
    async deleteAddress(id) {
      const confirmResult = await this.$confirm('此操作将永久删除该地址, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).catch((err) => err)
      if (confirmResult !== 'confirm') return this.$message.info('取消了删除')
      this.deleteLoading = true
      //查询id详细数据
      const { data: data } = await this.$http.post('/address/getById', { id: id })
      this.removeList = data.result
      // 删除地址操作
      const { data: res } = await this.$http.post('/address/delete', { ids: [this.removeList.id], standby: this.removeList.address })
      if (res.code !== 100) {
        this.deleteLoading = false
        return this.$message.error('删除地址失败')
      }
      this.deleteLoading = false
      this.$message.success('删除地址成功')
      this.getAddressList()
    },
    //监听 pagesize改变事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getAddressList()
    },
    //监听页码值改变事件
    handleCurrentChange(newPage) {
      this.queryInfo.current = newPage
      this.getAddressList()
    },
    //关闭添加地址框
    //清除所填信息
    closeAddDialog() {
      this.getUserList()
      this.selectUserKeys = []
      this.selectTypeKeys = []
      this.streetKey = []
      this.$refs.addAddressFormRef.resetFields()
    },
    //关闭编辑地址框
    //清除所填信息
    closeEditDialog() {
      this.streetKey2 = []
      this.selectEditUserKeys = []
      this.$refs.editAddressFormRef.resetFields()
    },
    // 是否显示搜索栏
    isShowAciton() {
      this.isShow = !this.isShow
    },
    // 刷新
    resetAciton() {
      this.loading = true
      this.queryParams = {}
      this.getAddressList()
    },
    //el-input无法输入 修复bug操作
    change(e) {
      this.$forceUpdate()
    },
  },
}
