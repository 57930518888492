//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      loading: true,
      //商品分类的数据列表
      cateList: [],
      queryInfo: {
        current: 1,
        pageSize: 50,
      },
      total: 0,
      // 添加分类对话框显示与隐藏
      addCateDialogVisible: false,
      // 添加分类的表单数据对象
      addCateForm: {
        name: '',
        isValid: 1,
        pid: 0,
        depth: 1,
        price: 0,
      },
      //添加分类表单验证规则对象
      addCateFormRules: {
        encoding: [{ required: true, message: '请输入处置小类编码', trigger: 'blur' }],
        rubbishCoding: [{ required: true, message: '请输入废物小类编码', trigger: 'blur' }],
        bigRubbishCoding: [{ required: true, message: '请输入废物大类编码', trigger: 'blur' }],
        bigEncoding: [{ required: true, message: '请输入处置大类编码', trigger: 'blur' }],
      },
      //选中的父级分类ID数组
      selectKeys: [],
      //指定级联选择器的配置对象
      cascaderProps: {
        value: 'id',
        label: 'name',
        children: 'categoryList',
        checkStrictly: true,
      },
      //父级分类的列表
      parentCateList: [],
      editCateDialogVisible: false,
      selectEditKeys: [],
      editCateForm: {
        name: '',
      },
      editCateFormRules: {
        name: [{ required: true, message: '请输入分类名称', trigger: 'blur' }],
      },
      //控制添加地址操作按钮是否可用
      disabledAdd: false,
      //控制修改地址操作按钮是否可用
      disabledEdit: false,
      queryParams: {},
      selectSearch: [],
      isShow: true,
      // 是否展开，默认全部折叠
      isExpandAll: false,
      // 重新渲染表格的状态
      refreshTable: true,
      // 删除loading
      deleteLoading: false,
      addLoading: false,
      editLoading: false,
      priceList: [],
      // 假删列表
      deleteList: {},
      selectKeys2: [],
    }
  },
  created() {
    this.getCateList()
    this.getParentCateList()
  },
  methods: {
    // 编辑字典 选择父级
    parentCateChange2() {
      //如果selectKeys数组中的length大于0 证明选中了父级分类
      //反之，就说明没有选中父级分类
      if (this.selectKeys2.length > 0) {
        //父级分类的Id
        this.editCateForm.pid = this.selectKeys2[this.selectKeys2.length - 1]
        //当前分类的等级赋值
        this.editCateForm.depth = this.selectKeys2.length + 1
        return
      } else {
        this.editCateForm.pid = 0
        //当前分类的等级赋值
        this.editCateForm.depth = 1
      }
      if (this.selectKeys[0] == 41) {
        this.editCateForm.name = this.editCateForm.name + '/' + this.editCateForm.price
      } else {
        this.editCateForm.name = this.editCateForm.name
      }
    },
    // 搜索选择分类
    searchChange() {
      this.queryParams.id = this.selectSearch[this.selectSearch.length - 1]
    },
    //搜索按钮
    async handleQuery() {
      this.loading = true
      const { data: res } = await this.$http.post('/caterory/fuzzyQuery', { id: this.queryParams.id })
      if (res.code !== 100) {
        this.loading = false
        return this.$message.error(res.msg)
      }
      this.loading = false
      this.cateList = res.result
      // this.total = res.result.total
      this.queryParams = {}
      this.selectSearch = []
    },
    //重置按钮
    resetQuery() {
      this.loading = true
      this.$refs.queryForm.resetFields()
      this.selectSearch = []
      this.getCateList()
    },
    //获取商品分类数据
    async getCateList() {
      const { data: res } = await this.$http.post('/caterory/query', {
        page: {
          current: this.queryInfo.current,
          pageSize: this.queryInfo.pageSize,
        },
      })
      if (res.code !== 100) {
        this.loading = false
        return this.$message.error('查询分类列表失败')
      }
      this.cateList = res.result.records
      this.total = res.result.total
      this.loading = false
    },
    //点击按钮，展示添加分类对话框
    showAddCateDialog() {
      //先获取父级分类的数据列表
      this.getParentCateList()
      this.addCateDialogVisible = true
    },
    //获取父级分类的数据列表
    async getParentCateList() {
      const { data: res } = await this.$http.post('/caterory/query', {
        page: {
          current: this.queryInfo.current,
          pageSize: this.queryInfo.pageSize,
        },
      })
      if (res.code !== 100) return this.$message.error('查询分类列表失败')
      this.parentCateList = res.result.records
      // this.parentCateList = this.getTreeData(res.result.records)
    },
    getTreeData(data) {
      /* for(var i=0;i<data.length;i++){
        if(data[i].depth == 1){
          data[i].categoryList = undefined
        }else{
          this.getTreeData(data[i].categoryList)
        }
      }
      return data */
      for (var i = 0; i < data.length; i++) {
        for (var j = 0; j < data[i].categoryList.length; j++) {
          if (data[i].categoryList[j].categoryList.length < 1) {
            data[i].categoryList[j].categoryList = undefined
          } else {
            this.getTreeData(data[i].categoryList[j].categoryList)
          }
        }
      }
      return data
    },
    //添加分类 级联选择器选择操作
    parentCateChange() {
      //如果selectKeys数组中的length大于0 证明选中了父级分类
      //反之，就说明没有选中父级分类
      if (this.selectKeys.length > 0) {
        //父级分类的Id
        this.addCateForm.pid = this.selectKeys[this.selectKeys.length - 1]
        //当前分类的等级赋值
        this.addCateForm.depth = this.selectKeys.length + 1
        return
      } else {
        this.addCateForm.pid = 0
        //当前分类的等级赋值
        this.addCateForm.depth = 1
      }
      if (this.selectKeys[0] == 41) {
        this.addCateForm.name = this.addCateForm.name + '/' + this.addCateForm.price
      } else {
        this.addCateForm.name = this.addCateForm.name
      }
    },
    //确认添加分类操作
    addCateAction() {
      if (this.addCateForm.pid == 41) {
        this.addCateForm.name = this.addCateForm.name + '/' + this.addCateForm.price
      } else {
        this.addCateForm.name = this.addCateForm.name
      }
      this.$refs.addCateFormRef.validate(async (valid) => {
        if (!valid) return
        this.addLoading = true
        const { data: res } = await this.$http.post('/caterory/renewal', {
          pid: this.addCateForm.pid,
          isValid: 1,
          depth: this.addCateForm.depth,
          name: this.addCateForm.name,
          encoding: this.addCateForm.encoding,
          rubbishCoding: this.addCateForm.rubbishCoding,
          bigRubbishCoding: this.addCateForm.bigRubbishCoding,
          bigEncoding: this.addCateForm.bigEncoding,
        })
        if (res.code !== 100) {
          this.selectKeys = []
          this.$refs.addCateFormRef.resetFields()
          this.addCateForm.pid = 0
          this.addCateForm.depth = 1
          this.addLoading = false
          return this.$message.error(res.msg)
        }
        this.addLoading = false
        this.$message.success('添加分类成功')
        this.selectKeys = []
        this.addCateForm.pid = 0
        this.addCateForm.depth = 1
        this.addCateDialogVisible = false
        this.getCateList()
      })
    },
    //打开编辑分类对话框
    async openEditDialog(id) {
      const { data: res } = await this.$http.post('/caterory/getById', { id: id })
      if (res.code !== 100) return this.$message.error('查询分类失败')
      this.editCateForm = res.result
      this.selectKeys2 = res.result.pid
      console.log(this.selectKeys2)
      this.priceList = this.editCateForm.name.split('/')
      this.editCateForm.name = this.priceList[0]
      this.editCateForm.price = this.priceList[1]
      //先获取父级分类的数据列表
      this.getParentCateList()
      this.getTypeById()
      this.editCateDialogVisible = true
    },
    //打开修改分类对话框
    async getTypeById() {
      const { data: res } = await this.$http.post('/caterory/getById', { id: this.editCateForm.id })
      if (res.code !== 100) return this, $message.error(res.msg)
      this.selectEditTypeKeys = res.result.id
    },
    //修改分类确认操作
    editCateAction() {
      if (this.editCateForm.pid == 41) {
        this.editCateForm.name = this.editCateForm.name + '/' + this.editCateForm.price
      } else {
        this.editCateForm.name = this.editCateForm.name
      }
      console
      this.$refs.editCateFormRef.validate(async (valid) => {
        if (!valid) return
        this.editLoading = true
        const { data: res } = await this.$http.post('/caterory/renewal', {
          id: this.editCateForm.id,
          pid: this.editCateForm.pid,
          isValid: 1,
          depth: this.editCateForm.depth,
          name: this.editCateForm.name,
          encoding: this.editCateForm.encoding,
          rubbishCoding: this.editCateForm.rubbishCoding,
          bigRubbishCoding: this.editCateForm.bigRubbishCoding,
          bigEncoding: this.editCateForm.bigEncoding,
        })
        if (res.code !== 100) {
          this.selectKeys = []
          this.addCateForm.pid = 0
          this.addCateForm.depth = 1
          this.editLoading = false
          return this.$message.error(res.msg)
        }
        this.editLoading = false
        this.$message.success('修改分类成功')
        this.selectKeys = []
        this.addCateForm.pid = 0
        this.addCateForm.depth = 1
        this.getCateList()
        this.editCateDialogVisible = false
      })
    },
    //根据id删除分类
    async deleteCate(id) {
      const confirmResult = await this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).catch((err) => err)
      if (confirmResult !== 'confirm') return this.$message.info('取消了删除')
      this.deleteLoading = true
      // 真删操作
      // const { data: res } = await this.$http.post('/caterory/remove', { ids: [id] })
      // if (res.code !== 100) {
      //   this.deleteLoading = false
      //   return this.$message.error('分类删除失败')
      // }
      // 假删操作
      // 根据Id查
      const { data: data } = await this.$http.post('/caterory/getById', { id: id })
      this.deleteList = data.result
      // 假删
      const { data: res } = await this.$http.post('/caterory/renewal', {
        id: this.deleteList.id,
        pid: this.deleteList.pid,
        isValid: 0,
        depth: this.deleteList.depth,
        name: this.deleteList.name,
      })
      if (res.code !== 100) {
        this.deleteLoading = false
        return this.$message.error(res.msg)
      }
      this.deleteLoading = false
      this.$message.success('分类删除成功')
      this.getCateList()
    },
    //清空添加分类对话框
    closeAddCateDialog() {
      this.$refs.addCateFormRef.resetFields()
      this.addCateForm.price = 0
      this.selectKeys = []
      this.addCateForm.pid = 0
      this.addCateForm.depth = 1
    },
    //清空编辑分类对话框
    closeeditCateDialog() {
      this.$refs.editCateFormRef.resetFields()
    },

    //监听 pagesize改变事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getCateList()
    },
    //监听页码值改变事件
    handleCurrentChange(newPage) {
      this.queryInfo.current = newPage
      this.getCateList()
    },
    // 是否显示搜索栏
    isShowAciton() {
      this.isShow = !this.isShow
    },
    // 刷新
    resetAciton() {
      this.loading = true
      this.queryParams = {}
      this.getCateList()
    },
    //控制是否展开
    toggleExpandAll() {
      this.refreshTable = false
      this.isExpandAll = !this.isExpandAll
      this.$nextTick(() => {
        this.refreshTable = true
      })
    },
    //el-input无法输入 修复bug操作
    change(e) {
      this.$forceUpdate()
    },
  },
}
