//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cityData from '../Company/citydata'
import FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
export default {
    name: 'VueQingyunOrderReport',

    data() {
        return {
            queryParams: {
                street: undefined,
                beginTime: undefined,
                finishTime: undefined
            },
            time: [],
            loading: false,
            // 充值类型
            MoneyTypeList: [],
            orderStatistics: [],
            cityData,
            streetKey: [],
        };
    },
    created() {
        this.getMoneyType()
    },
    mounted() {

    },

    methods: {
        // 筛选 选择区域
        changeStreet() {
            this.queryParams.street = this.streetKey[this.streetKey.length - 1]
        },
        arraySpanMethod({ row, column, rowIndex, columnIndex }) {
            // 合并行  产品名字相同合并
            if (columnIndex === 0) {
                if (rowIndex === 0 || row.street != this.orderStatistics[rowIndex - 1].street) {
                    let rowspan = 0;
                    this.orderStatistics.forEach(element => {
                        if (element.street === row.street) {
                            rowspan++;
                        }
                    });
                    return [rowspan, 1];
                } else {
                    return [0, 0];
                }
            }
        },
        // 导出按钮
        exportExcel(elementName, fileName) {
            const wb = XLSX.utils.table_to_book(this.clearHead(elementName), { raw: true });
            const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' });
            FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
        },
        // 导出时，去除展开和操作列
        clearHead(elementName) {
            const tableDom = document.querySelector('#' + elementName).cloneNode(true);
            const tableHeader = tableDom.querySelector('.el-table__header-wrapper');
            const tableBody = tableDom.querySelector('.el-table__body');
            tableHeader.childNodes[0].append(tableBody.childNodes[1]);
            const headerDom = tableHeader.childNodes[0].querySelectorAll('th');
            for (const key in headerDom) {
                if (headerDom[key].innerText === '操作' || headerDom[key].innerText === '查看') {
                    headerDom[key].remove();
                }
            }
            // 清理掉checkbox 和操作的button
            const tableList = tableHeader.childNodes[0].childNodes[2].querySelectorAll('td');
            for (let key = 0; key < tableList.length; key++) {
                if (tableList[key].querySelectorAll('.el-button').length > 0) {
                    tableList[key].remove();
                }
            }
            return tableHeader;
        },
        //获取充值类型
        async getMoneyType() {
            this.loading = true
            const { data: res } = await this.$http.post('/caterory/query', {
                page: {
                    current: 1,
                    pageSize: 200,
                },
            })
            if (res.code !== 100) return this.$message.error(res.msg)
            this.MoneyTypeList = res.result.records.filter((item) => item.id == 66)[0].categoryList
            this.loading = false
            // this.getPayReportList()
        },
        //获取充值类型
        async getPayReportList() {
            const { data: res } = await this.$http.post('/advancePayment/statistics', {

            })
            this.orderStatistics = res.result
            this.loading = false
        },
        // 搜索
        async handleQuery() {
            console.log(this.time)
            this.loading = true
            if (this.time.length !== 0) {
                this.queryParams.beginTime = this.time[0]
                this.queryParams.finishTime = this.time[1]
            }
            const { data: res } = await this.$http.post('/advancePayment/statistics', {
                street: this.queryParams.street,
                beginTime: this.queryParams.beginTime,
                finishTime: this.queryParams.finishTime

            })
            this.orderStatistics = res.result
            this.loading = false
        },
        // 重置
        resetQuery() {
            // this.loading = true
            this.time = []
            this.streetKey = []
            this.queryParams = {}
            this.orderStatistics = []
            // this.getPayReportList()
        },
    },
};
