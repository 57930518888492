//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cityData from './citydata'
import FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import QRCode from 'qrcodejs2' // 引入qrcode
import moment from "moment"
export default {
  name: 'qrcodejs2',
  data() {
    return {
      companyUserList: [],
      companyList: [],
      queryInfo: {
        current: 1,
        pageSize: 50,
      },
      total: 0,
      //查询对象
      queryParams: {
        userName: undefined,
        principal: undefined,
        enterpriseCode: undefined,
        time: undefined,
        haveTime: undefined,
        noHaveTime: undefined,
      },
      // 地址选择
      cityData,
      //添加
      addCompanyDialog: false,
      addCompanyForm: {
        userName: '',
        enterpriseCode: '',
        phone: '',
        principal: '',
        isValid: 1,
      },
      addCompanyFormRules: {
        userName: [{ required: true, message: '请输入公司名', trigger: 'blur' }],
        enterpriseCode: [{ required: true, message: '请输入企业征信号', trigger: 'blur' }],
        principal: [{ required: true, message: '请输入负责人名称', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          {
            pattern: /^1\d{10}$/,
            message: '注意手机号码格式,长度11位',
          },
        ],
      },
      //编辑
      editCompanyDialog: false,
      editCompanyForm: {
        userName: '',
        principal: '',
        enterpriseCode: '',
        phone: '',
        isValid: 1,
      },
      editCompanyFormRules: {
        userName: [{ required: true, message: '请输入公司名', trigger: 'blur' }],
        principal: [{ required: true, message: '请输入负责人名称', trigger: 'blur' }],
        enterpriseCode: [{ required: true, message: '请输入企业征信号', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          {
            pattern: /^1\d{10}$/,
            message: '注意手机号码格式,长度11位',
          },
        ],
      },
      userList: [],
      //级联选择框配置对象
      userProps: {
        value: 'id',
        label: 'name',
        children: 'categoryList',
        checkStrictly: true,
      },
      //级联选择器双向绑定到的数组
      selectUserKeys: [],
      removeList: {},
      unitList: [],
      unit2List: [],
      //级联选择框配置对象
      unitProps: {
        value: 'id',
        label: 'name',
        // children: 'categoryList',
        // checkStrictly: true,
      },
      editPriceForm: {},
      //级联选择器双向绑定到的数组
      selectUnitKeys: [],
      selectUnit2Keys: [],
      selectUnit3Keys: [],
      loading: true,
      editPriceDialog: false,
      priceIdList: [],
      priceList: [],
      price10: 400,
      qrcodeDialog: false,
      qrcodeList: [],
      access_token: '',
      urlLink: '',
      //选择身份
      selectTypeKeys: [],
      typeList: [],
      type2List: [],
      typeProps: {
        value: 'id',
        label: 'name',
        // children: 'categoryList',
      },
      pid: '',
      query: '',
      isShow: true,
      //删除loading
      deleteLoading: false,
      addLoading: false,
      editLoading: false,
      totalpriceList: [],
      isDisabled: true,
      vforList: [],
      vforList2: [],
      setPriceList: [],
      setNewPriceList: [],
      vforList3: [],
      vforListId3: [],
      vforListUnit: [],
      // 保存名称数组
      unitListName: [],
      // 是否审核公司
      selectIsAuditKeys: [],
      selectIsAuditKeys2: [],
      isAuditList: [
        {
          value: 0,
          label: '未审核',
        },
        {
          value: 1,
          label: '已审核',
        },
      ],
      //companyUserList: [],
      seeUserLoading: false,
      rowIndex: 0,
      streetKey: [],
      streetKey2: [],
      streetKey3: [],
      isshowTable: false,
      totalcompanyList: [],
      beginTime: undefined, //开始时间
      finishTime: undefined, //结束时间
      // 选择导出数据数组
      daoChuChoseList: [],
      // 垃圾类型
      ljType: [],
      ljKey: [],
      ljTypeProps: {
        value: 'id',
        label: 'name',
        children: 'categoryList',
        checkStrictly: true,
      },
      // 批量清零id数组
      ids: [],
      // 增加协议到期时间
      agreementTime: '',
      // 台账
      taiPayDiaog: false,
      addTaiPayForm: {},
      addTaiPayFormRules: {
        wasteTypeCode: [{ required: true, message: '请输入废物大类代码', trigger: 'blur' }],
        disposalModelKey: [{ required: true, message: '请输入处置小类编码', trigger: 'blur' }],
        disposalTypeKey: [{ required: true, message: '请输入处置大类编码', trigger: 'blur' }],
        transferInAmount: [{ required: true, message: '请输入转入量', trigger: 'blur' }],
        disposalAmount: [{ required: true, message: '请输入处置量', trigger: 'blur' }],
        currentInventory: [{ required: true, message: '请输入当前库存量', trigger: 'blur' }],
        changedInventory: [{ required: true, message: '请输入库存变动量', trigger: 'blur' }],
      },
      // 合签公司
      selectUserNameKeys: [],
      selectUserNameKeys2: [],
      changeCompanyLoading: false,
      userNameList: [],
      // 删除子合签id存放
      idListYuan: '',
      // 修改主合签公司
      sonHeDialog: false,
      sonHeList: [],
      fatherObj: {},
      sonObj: {},
      // 搜索标签
      labelKey: [],
      // 搜索存放label字符串
      labelString: undefined,
      // 新增标签存放
      addLabelKey: [],
      // 编辑标签存放
      editLabelKey: [],
      labelList: [],
      // 查询是否已推送
      isPushKey: undefined,
      pushList: [
        {
          id: 1,
          name: '未推送',
          value: 0
        },
        {
          id: 2,
          name: '已推送',
          value: 1
        }
      ],
      // 有清运时间筛选
      haveBeginTime: undefined,
      haveFinishTime: undefined,
      noHaveBeginTime: undefined,
      noHaveFinishTime: undefined,
      // 设置二维码天数
      setTimesDialog: false,
      erTimes: null,
    }
  },
  created() {
    this.getCompanyList()
    this.getLabelList()
    this.getUnitTypeList()
    this.getTypeList()
    this.getUserNameList()
  },
  methods: {
    async openSetTimesDialog() {
      const { data: res } = await this.$http.get('/company/getPastDue')
      if (res.code !== 100) return this.$message.error(res.msg)
      this.erTimes = res.result
      this.setTimesDialog = true
    },
    async setTimeAction() {
      const { data: res } = await this.$http.get(`/company/pastDue?value=${this.erTimes}`)
      if (res.code !== 100) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.closeSetTimesDialog()
    },
    // 关闭 设置二维码对话框
    closeSetTimesDialog() {
      this.erTimes = null
      this.setTimesDialog = false
    },
    // 获取标签数据源
    async getLabelList() {
      const { data: res } = await this.$http.post('/tag/inquire', {
        page: {
          current: this.queryInfo.current,
          pageSize: this.queryInfo.pageSize,
        },
        data: {
          isValid: 1,
        },
      })
      this.labelList = res.result.records
    },
    // 打开修改修改主合签公司
    async openSonHeDialog(item) {
      if (item.jointSignature == '' || item.jointSignature == null || item.jointSignature == undefined) {
        this.fatherObj = {}
        this.sonHeList = []
      } else {
        this.fatherObj = item
        const { data: res } = await this.$http.post('/company/findJointSignature', {
          data: {
            jointSignature: item.jointSignature
          }
        })
        this.sonHeList = res.result
      }
      this.sonHeDialog = true
    },
    // 子合签选择操作
    // table选择框
    handleSelectionChangeSon(val) {
      if (val.length > 1) {
        this.$message.error('只能选择一家公司')
        this.$refs.sonHeRef.clearSelection()
      } else {
        this.sonObj = val[0]
      }
    },
    // 修改主合签确认操作
    async editHeAction() {
      this.addLoading = true
      const { data: res } = await this.$http.post('/company/amendMain', {
        company1: this.fatherObj,
        company2: this.sonObj,
      })
      if (res.code !== 100) {
        this.$message.error(res.msg)
        return this.addLoading = false
      }
      this.$message.success('修改成功')
      this.addLoading = false
      this.sonHeDialog = false
      this.$refs.sonHeRef.clearSelection()
      this.getCompanyList()
    },
    // 关闭修改主合签公司
    closeSonHeDialog() {
      this.fatherObj = {}
      this.sonObj = {}
      this.sonHeList = []
      this.$refs.sonHeRef.clearSelection()
      this.sonHeDialog = false
    },
    // 选择合签公司
    remoteMethod2(query) {
      if (query !== '') {
        this.changeCompanyLoading = true
        setTimeout(() => {
          this.selectInput2(query)
        }, 200)
      } else {
        this.getUserNameList()
        // this.conpanyList = []
      }
    },
    async selectInput2(query) {
      const { data: res } = await this.$http.post('/company/isJointSignature', {
        data: {
          userName: query,
        },
        page: {
          current: 1,
          pageSize: 10,
        },
        searchVal: query,
      })
      this.userNameList = res.result.records
      this.changeCompanyLoading = false
    },
    //获取所属用户列表
    async getUserNameList() {
      const { data: res } = await this.$http.post('/company/isJointSignature', {
        page: {
          current: 1,
          pageSize: 10,
        },
      })
      if (res.code !== 100) return this.$message.error('获取公司列表失败')
      this.userNameList = res.result.records
    },
    // 选择垃圾类型
    async changeLJ() {
      const { data: res } = await this.$http.post('/caterory/getById', {
        id: this.ljKey[this.ljKey.length - 1]
      })
      this.addTaiPayForm.wasteTypeCode = res.result.bigRubbishCoding
      this.addTaiPayForm.disposalTypeKey = res.result.bigEncoding
      this.addTaiPayForm.disposalModelKey = res.result.encoding
      this.$forceUpdate();
    },
    // 推送公司基本数据
    async addCompanyInfor() {
      if (this.daoChuChoseList.length == 0) {
        this.$message.error('请选择公司')
      } else {
        this.loading = true
        const { data: res } = await this.$http.post('/company/push', this.daoChuChoseList)
        if (res.code !== 100) {
          this.loading = false
          return this.$message.error(res.msg)
        }
        this.$message.success('上传成功')
        this.$refs.table.clearSelection()
        this.getCompanyList()
        this.loading = false
      }
    },
    // 关闭台账
    closeTaiPayDialog() {
      this.taiPayDiaog = false
      this.ljKey = []
      this.addTaiPayForm = {}
    },
    // 打开台账
    openTaiPayDialog() {
      this.addTaiPayForm = {}
      this.taiPayDiaog = true
    },
    // 上传台账
    uploadTaiPayAction() {
      this.$refs.addTaiPayFormRef.validate(async (valid) => {
        if (!valid) return
        this.addLoading = true
        const { data: res } = await this.$http.post('/company/uploadLedger', this.addTaiPayForm)
        if (res.code !== 100) {
          this.addLoading = false
          return this.$message.error(res.msg)
        }
        this.addLoading = false
        this.$message.success('上传成功')
        this.getCompanyList()
        this.taiPayDiaog = false
        this.addTaiPayForm = {}
      })
    },
    dateFormat(row, column) {
      let date = row[column.property]
      if (date !== null && date !== undefined && date !== '') {
        return moment(date).format('YYYY-MM-DD')
      } else {
        return
      }

    },
    // table选择框
    handleSelectionChange(val) {
      this.daoChuChoseList = val
      const data = val
      this.ids = []
      data.forEach(item => {
        this.ids.push(item.id)
      });
    },
    // 批量清零
    async cleanBalance() {
      const confirmResult = await this.$confirm('是否需要将公司清零?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).catch((err) => err)
      if (confirmResult !== 'confirm') return this.$message.info('取消了清除')
      const { data: res } = await this.$http.post('/company/cleanBalance', {
        ids: this.ids
      })
      if (res.code !== 100) return this.$message.error(res.msg)
      this.$message.success('清除成功')
      let $table = this.$refs.table
      this.companyList.map((item) => {
        $table.toggleRowExpansion(item, false)
      })
      this.getCompanyList()
    },
    // 第二种导出功能
    exportExcel(elementName, fileName) {
      const wb = XLSX.utils.table_to_book(this.clearHead(elementName), { raw: true });
      const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' });
      FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
    },
    // 导出时，去除展开和操作列
    clearHead(elementName) {
      const tableDom = document.querySelector('#' + elementName).cloneNode(true);
      const tableHeader = tableDom.querySelector('.el-table__header-wrapper');
      const tableBody = tableDom.querySelector('.el-table__body');
      tableHeader.childNodes[0].append(tableBody.childNodes[1]);
      const headerDom = tableHeader.childNodes[0].querySelectorAll('th');
      for (const key in headerDom) {
        if (headerDom[key].innerText === '操作' || headerDom[key].innerText === '查看') {
          headerDom[key].remove();
        }
      }
      // 清理掉checkbox 和操作的button
      const tableList = tableHeader.childNodes[0].childNodes[2].querySelectorAll('td');
      for (let key = 0; key < tableList.length; key++) {
        if (tableList[key].querySelectorAll('.el-button').length > 0) {
          tableList[key].remove();
        }
      }
      return tableHeader;
    },
    // 编辑公司 选择街道
    changeStreet3() {
      this.queryParams.street = this.streetKey3[this.streetKey3.length - 1]
    },
    // 编辑公司 选择街道
    changeStreet2() {
      this.editCompanyForm.street = this.streetKey2[this.streetKey2.length - 1]
      this.editCompanyForm.city = this.streetKey2[0]
      this.editCompanyForm.district = this.streetKey2[1]
    },
    // 新增公司 添加地址
    changeStreet() {
      this.addCompanyForm.street = this.streetKey[this.streetKey.length - 1]
      this.addCompanyForm.city = this.streetKey[0]
      this.addCompanyForm.district = this.streetKey[1]
    },
    tableCellClassName({ row, column, rowIndex, columnIndex }) {
      row.index = rowIndex
      column.index = columnIndex
    },
    // 获取位置
    cellClick(row, column, cell, event) {
      this.rowIndex = row.index
    },
    // 公司解绑
    async unbundleAction(row) {
      const confirmResult = await this.$confirm('此操作将永久解除该公司, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).catch((err) => err)
      if (confirmResult !== 'confirm') return this.$message.info('取消了删除')
      const { data: res } = await this.$http.post('/admin/unbundle', {
        companyId: row.id,
        id: row.children[this.rowIndex].id,
      })
      if (res.code !== 100) return this.$message.error(res.msg)
      this.$message.success('解绑成功')
      let $table = this.$refs.table
      this.companyList.map((item) => {
        $table.toggleRowExpansion(item, false)
      })
      this.getCompanyList()
    },
    // 展开
    async toogleExpand(row) {
      const { data: res } = await this.$http.post('/company/findUser', { companyId: row.id })
      if (res.code !== 100) return this.$message.error(res.msg)
      row.children = res.result
      let $table = this.$refs.table
      // this.companyList.map((item) => {
      //   $table.toggleRowExpansion(item, false)
      // })
      $table.toggleRowExpansion(row)
    },
    // 获取公司下的用户
    async getCompanyUserList(id) {
      const { data: res } = await this.$http.post('/company/findUser', { companyId: id })
      if (res.code !== 100) {
        this.seeUserLoading = false
        return this.$message.error(res.msg)
      }
      // this.seeUserLoading = false
      this.companyUserList = res.result
    },
    // 添加公司 选择是否审核
    handleChangeIsAudit() {
      this.addCompanyForm.isAudit = this.selectIsAuditKeys[this.selectIsAuditKeys.length - 1]
    },
    // 编辑公司 选择是否审核
    handleChangeIsAudit2() {
      this.editCompanyForm.isAudit = this.selectIsAuditKeys2[this.selectIsAuditKeys2.length - 1]
    },
    //获取分类数据
    async getTypeList() {
      const { data: res } = await this.$http.post('/caterory/query', {
        page: {
          current: 1,
          pageSize: 200,
        },
      })
      if (res.code !== 100) return this.$message.error('查询分类列表失败')
      this.totalpriceList = res.result.records.filter((item) => item.id == 41)[0].categoryList
      this.typeList = res.result.records.filter((item) => item.id == 89)
      this.type2List = this.typeList[0].categoryList
      this.ljType = res.result.records.filter((item) => item.id == 1)[0].categoryList
    },
    //获取清运单位字典表
    async getUnitTypeList() {
      const { data: res } = await this.$http.post('/caterory/query', {
        page: {
          current: this.queryInfo.current,
          pageSize: this.queryInfo.pageSize,
        },
      })
      if (res.code !== 100) return this.$message.error('查询清运单位字典表失败')
      this.unitList = res.result.records.filter((item) => item.id == 41 && item.isValid == 1)[0].categoryList
      // console.log(this.unitList)
      for (let i = 0; i < this.unitList.length; i++) {
        this.unitListName = this.unitList[i].name.split('/')
        this.unitList[i].name = this.unitListName[0]
        this.unit2List = this.unitList
        this.vforList[i] = { unit: this.unitList[i].id, unitPrice: this.unitListName[1] }
      }
    },
    //获取用户列表
    async getUserList() {
      const { data: res } = await this.$http.post('/admin/user/userInDetail', {
        page: {
          current: this.queryInfo.current,
          pageSize: this.queryInfo.pageSize,
        },
      })
      if (res.code !== 100) return this.$message.error('查询用户失败')
      this.userList = res.result.records
    },
    //添加公司 选择用户操作
    handleChange() {
      this.addCompanyForm.cid = this.selectUserKeys[this.selectUserKeys.length - 1]
    },
    //二维码 选择身份
    handleChange5() {
      this.pid = this.selectTypeKeys[this.selectTypeKeys.length - 1]
      this.isDisabled = false
      this.getData()
    },
    //搜索操作
    async handleQuery() {
      if (this.labelKey.length == 0) {
        this.labelKey = []
        this.labelString = undefined
      } else {
        this.labelString = this.labelKey.join(',')
      }
      if (this.queryParams.time !== undefined && this.queryParams.time !== null) {
        this.beginTime = this.queryParams.time[0]
        this.finishTime = this.queryParams.time[1]
      } else {
        this.beginTime = undefined
        this.finishTime = undefined
      }
      console.log(this.beginTime)
      if (this.queryParams.haveTime !== undefined && this.queryParams.haveTime !== null) {
        this.haveBeginTime = this.queryParams.haveTime[0]
        this.haveFinishTime = this.queryParams.haveTime[1]
      } else {
        this.haveBeginTime = undefined
        this.haveFinishTime = undefined
      }
      if (this.queryParams.noHaveTime !== undefined && this.queryParams.noHaveTime !== null) {
        this.noHaveBeginTime = this.queryParams.noHaveTime[0]
        this.noHaveFinishTime = this.queryParams.noHaveTime[1]
      } else {
        this.noHaveBeginTime = undefined
        this.noHaveFinishTime = undefined
      }
      this.loading = true
      const { data: res } = await this.$http.post('/company/query', {
        data: {
          userName: this.queryParams.userName,
          principal: this.queryParams.principal,
          street: this.queryParams.street,
          enterpriseCode: this.queryParams.enterpriseCode,
          label: this.labelString,
          isPush: this.isPushKey,
          hasOrderBeginTime: this.haveBeginTime,
          hasOrderFinishTime: this.haveFinishTime,
          noOrderBeginTime: this.noHaveBeginTime,
          noOrderFinishTime: this.noHaveFinishTime,
          beginTime: this.beginTime,
          finishTime: this.finishTime,
        },
        page: {
          current: this.queryInfo.current,
          pageSize: this.queryInfo.pageSize,
        },
      })
      if (res.code !== 100) {
        this.loading = false
        return this.$message.error(res.msg)
      }
      this.loading = false
      let $table = this.$refs.table
      this.companyList.map((item) => {
        $table.toggleRowExpansion(item, false)
      })
      this.companyList = res.result.records
      this.total = res.result.total
    },
    //重置按钮操作
    resetQuery() {
      this.loading = true
      this.finishTime = undefined
      this.beginTime = undefined
      this.haveBeginTime = undefined
      this.haveFinishTime = undefined
      this.noHaveBeginTime = undefined
      this.noHaveFinishTime = undefined
      this.labelKey = []
      this.isPushKey = undefined
      this.labelString = undefined
      this.queryInfo.current = 1
      this.queryInfo.pageSize = 50
      this.$refs.table.clearSelection();
      this.$refs.queryForm.resetFields()
      this.streetKey3 = []
      let $table = this.$refs.table
      this.companyList.map((item) => {
        $table.toggleRowExpansion(item, false)
      })
      this.getCompanyList()
    },
    //获取公司列表
    async getCompanyList() {
      this.loading = true
      const { data: res } = await this.$http.post('/company/query', {
        page: {
          current: this.queryInfo.current,
          pageSize: this.queryInfo.pageSize,
        },
        data: {
          userName: this.queryParams.userName,
          principal: this.queryParams.principal,
          street: this.queryParams.street,
          enterpriseCode: this.queryParams.enterpriseCode,
          beginTime: this.beginTime,
          finishTime: this.finishTime,
          label: this.labelString,
          isPush: this.isPushKey,
          hasOrderBeginTime: this.haveBeginTime,
          hasOrderFinishTime: this.haveFinishTime,
          noOrderBeginTime: this.noHaveBeginTime,
          noOrderFinishTime: this.noHaveFinishTime,
        },
      })
      if (res.code !== 100) return this.$message.error(res.msg)
      this.companyList = res.result.records
      this.total = res.result.total
      this.loading = false
      for (let i = 0; i < this.companyList.length; i++) {
        this.companyList[i].children = []
      }
    },
    daochuAction() {
      if (this.daoChuChoseList.length == 0) {
        this.getTotalCompanyList()
      } else {
        this.choseDaoChuAction()
      }
    },
    choseDaoChuAction() {
      this.loading = true
      this.totalcompanyList = this.daoChuChoseList
      setTimeout(() => {
        this.loading = false
        this.$refs.table.clearSelection();
        this.exportExcel('educe-table', '公司名单')
      }, 1000);
    },
    //获取全部公司列表
    async getTotalCompanyList() {
      this.loading = true
      const { data: res } = await this.$http.post('/company/query', {
        page: {
          current: 1,
          pageSize: 99999,
        },
        data: {
          userName: this.queryParams.userName,
          principal: this.queryParams.principal,
          street: this.queryParams.street,
          enterpriseCode: this.queryParams.enterpriseCode,
          beginTime: this.beginTime,
          finishTime: this.finishTime,
          label: this.labelString,
          isPush: this.isPushKey,
          hasOrderBeginTime: this.haveBeginTime,
          hasOrderFinishTime: this.haveFinishTime,
          noOrderBeginTime: this.noHaveBeginTime,
          noOrderFinishTime: this.noHaveFinishTime,
        },
      })
      if (res.code !== 100) return this.$message.error(res.msg)
      this.totalcompanyList = res.result.records
      setTimeout(() => {
        this.loading = false
        this.exportExcel('educe-table', '公司名单')
      }, 1000);
    },
    //打开新增公司对话框
    openAddressDialog() {
      this.getUserList()
      this.addLabelKey = []
      this.addCompanyDialog = true
    },
    // 根据公司id查
    async heCompanyId(id) {
      const { data: res } = await this.$http.post('/company/getbyId', { id: id })
      this.userNameList.push(res.result)
    },
    //确定添加公司操作
    addCompanyAciton() {
      this.$refs.addCompanyFormRef.validate(async (valid) => {
        if (!valid) return
        this.addLoading = true
        let companyKey = ''
        if (this.selectUserNameKeys.length !== 0) {
          companyKey = this.selectUserNameKeys.join(',')
        }
        let companyLabel = ''
        if (this.addLabelKey.length !== 0) {
          companyLabel = this.addLabelKey.join(',')
        }
        const { data: res } = await this.$http.post('/company/renewal', {
          userName: this.addCompanyForm.userName,
          enterpriseCode: this.addCompanyForm.enterpriseCode,
          isValid: 1,
          phone: this.addCompanyForm.phone,
          principal: this.addCompanyForm.principal,
          street: this.addCompanyForm.street,
          cid: this.addCompanyForm.cid,
          companyList: this.vforList,
          agreementTime: this.agreementTime,
          jointSignature: companyKey,
          label: companyLabel,
          detailedAddress: this.addCompanyForm.detailedAddress,
          longitude: this.addCompanyForm.longitude,
          latitude: this.addCompanyForm.latitude,
          city: this.addCompanyForm.city,
          district: this.addCompanyForm.district,
        })
        if (res.code !== 100) {
          this.addLoading = false
          return this.$message.error(res.msg)
        }
        this.addLoading = false
        this.$message.success('添加公司信息成功')
        this.agreementTime = ''
        this.getCompanyList()
        this.selectUserNameKeys = []
        this.addCompanyDialog = false
      })
    },
    //打开修改公司对话框
    async openEditDialog(id) {
      this.getUserNameList()
      this.idListYuan = ''
      const { data: res } = await this.$http.post('/company/getbyId', { id: id })
      if (res.code !== 100) return this.$message.error('查询公司信息失败')
      this.editCompanyForm = res.result
      this.streetKey2 = res.result.street
      if (res.result.label !== '' && res.result.label !== null && res.result.label !== undefined) {
        this.editLabelKey = res.result.label.split(',')
      } else {
        this.editLabelKey = []
      }
      this.agreementTime = res.result.agreementTime
      for (let i in res.result.unitsPrice) {
        this.priceIdList.push(parseInt(i))
        this.priceList.push(parseInt(res.result.unitsPrice[i]))
      }
      for (let i = 0; i < this.priceIdList.length; i++) {
        const { data: res } = await this.$http.post('/caterory/getById', { id: this.priceIdList[i] })
        if (res.result.isValid == 0) {
          this.priceIdList.splice(i, 1)
          i--
        }
      }
      for (let i = 0; i < this.priceIdList.length; i++) {
        this.vforList2[i] = { unit: this.priceIdList[i], unitPrice: this.priceList[i] }
      }
      this.selectUserNameKeys2 = []
      if (res.result.jointSignature !== '' && res.result.jointSignature !== null && res.result.jointSignature !== undefined) {
        this.idListYuan = res.result.jointSignature
        let arrString = res.result.jointSignature.split(',')
        for (let i = 0; i < arrString.length; i++) {
          this.selectUserNameKeys2.push(parseInt(arrString[i]))
        }
        if (this.selectUserNameKeys2.length !== 0) {
          const res = this.selectUserNameKeys2.filter(item1 => !this.userNameList.some(item2 => item2.id === item1))
          if (res.length !== 0) {
            for (let i = 0; i < res.length; i++) {
              this.heCompanyId(res[i])
            }
          }

        }
      }
      this.editCompanyDialog = true
    },

    //修改确认操作
    editCompanyAciton() {
      this.$refs.editCompanyFormRef.validate(async (valid) => {
        if (!valid) return
        this.editLoading = true
        let companyKey = ''
        if (this.selectUserNameKeys2.length !== 0) {
          companyKey = this.selectUserNameKeys2.join(',')
        }
        let companyLabel = ''
        if (this.editLabelKey.length !== 0) {
          companyLabel = this.editLabelKey.join(',')
        }
        const { data: res } = await this.$http.post('/company/renewal', {
          id: this.editCompanyForm.id,
          enterpriseCode: this.editCompanyForm.enterpriseCode,
          isValid: 1,
          street: this.editCompanyForm.street,
          phone: this.editCompanyForm.phone,
          plateNumber: this.editCompanyForm.plateNumber,
          principal: this.editCompanyForm.principal,
          userName: this.editCompanyForm.userName,
          agreementTime: this.agreementTime,
          companyList: this.vforList2,
          jointSignature: companyKey,
          jointSignatureT: this.idListYuan,
          label: companyLabel,
          detailedAddress: this.editCompanyForm.detailedAddress,
          longitude: this.editCompanyForm.longitude,
          latitude: this.editCompanyForm.latitude,
          city: this.editCompanyForm.city,
          district: this.editCompanyForm.district,
          isJoint: this.editCompanyForm.isJoint,
          mainCompany: this.editCompanyForm.mainCompany,
        })
        if (res.code !== 100) {
          this.editLoading = false
          return this.$message.error(res.msg)
        }
        this.editLoading = false
        this.$message.success('修改公司信息成功')
        this.getCompanyList()
        this.editCompanyDialog = false
      })
    },
    //根据ID 删除公司信息
    async deleteCompany(id) {
      const confirmResult = await this.$confirm('此操作将永久删除该公司, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).catch((err) => err)
      if (confirmResult !== 'confirm') return this.$message.info('取消了删除')
      this.deleteLoading = true
      //查询id详细数据
      const { data: data } = await this.$http.post('/company/getbyId', { id: id })
      this.removeList = data.result
      const { data: res } = await this.$http.post('/company/remove', { ids: [this.removeList.id], standby: this.removeList.userName })
      if (res.code !== 100) {
        this.deleteLoading = false
        return this.$message.error(res.msg)
      }
      this.deleteLoading = false
      this.$message.success('删除用户信息成功')
      this.getCompanyList()
    },
    //关闭添加对话框
    closeAddDialog() {
      this.$refs.addCompanyFormRef.resetFields()
      this.selectUserKeys = []
      this.selectIsAuditKeys2 = []
      this.selectAddressKeys = []
      this.streetKey = []
      this.agreementTime = ''
    },
    //关闭修改对话框 清除内容
    closeEditDialog() {
      this.$refs.editCompanyFormRef.resetFields()
      this.selectUnitKeys = []
      this.selectUnit2Keys = []
      this.selectUnit3Keys = []
      this.priceList = []
      this.streetKey2 = []
      this.priceIdList = []
      this.vforList2 = []
      this.selectIsAuditKeys2 = []
      this.$refs.editPriceFormRef.resetFields()
      this.agreementTime = ''
    },
    closeQrcodeDialog() {
      this.selectTypeKeys = []
      this.$refs.qrCodeUrl.innerHTML = ''
    },
    //监听 pagesize改变事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getCompanyList()
    },
    //监听页码值改变事件
    handleCurrentChange(newPage) {
      this.queryInfo.current = newPage
      this.getCompanyList()
    },
    //生成二维码
    async openQrcodeDialog(id) {
      const { data: res } = await this.$http.post('/company/getbyId', { id: id })
      if (res.code !== 100) return this.$message.error(res.msg)
      this.qrcodeList = res.result
      const { data: user } = await this.$http.post('/admin/user/detail', { id: this.qrcodeList.aid })
      if (res.code !== 100) return this.$message.error('获取用户信息失败')
      this.qrcodeDialog = true
    },
    qrcodeAciton() {
      this.$refs.qrCodeUrl.innerHTML = ''
      this.qrcode = this.urlLink
      this.$nextTick(() => {
        this.creatQrCode()
      })
      this.isDisabled = true
    },
    //获取微信url_link
    async getData() {
      const { data: res } = await this.$http.post('/company/accessToken', {
        id: parseInt(this.qrcodeList.id),
        pid: this.pid,
        appId: 'wxd07b9512e10d5538',
        secret: '53f187318da8feaaf1ae7f349f934f0d',
      })
      if (res.code !== 100) return this.$message.error(res.msg)
      this.urlLink = res.result
    },
    //生成二维码
    creatQrCode() {
      new QRCode(this.$refs.qrCodeUrl, {
        text: this.qrcode, // 需要转换为二维码的内容
        width: 200,
        height: 200,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H,
      })
    },
    //是否显示搜索栏
    isShowAciton() {
      this.isShow = !this.isShow
    },
    //刷新数据
    async resetAciton() {
      this.loading = true
      this.queryParams = {}
      let $table = this.$refs.table
      this.companyList.map((item) => {
        $table.toggleRowExpansion(item, false)
      })
      this.getCompanyList()
    },
    //el-input无法输入 修复bug操作
    change(e) {
      this.$forceUpdate()
    },
  },
}
