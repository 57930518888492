import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import Home from '../components/Home.vue'
import Welcome from '../components/Welcome.vue'
import Users from '../components/Users/Users.vue'
import Roles from '../components/Roles/Roles.vue'
import Orders from '../components/Orders/Orders.vue'
import orderReport from '../components/Orders/orderReport.vue'
import areaReport from '../components/Orders/areaReport.vue'
import cateReport from '../components/Orders/cateReport.vue'
import payReport from '../components/Orders/payReport.vue'
import Address from '../components/Address/Address.vue'
import Car from '../components/Car/index.vue'
import Label from '../components/Label/index.vue'
import CateGory from '../components/CateGory/CateGory.vue'
import Company from '../components/Company/Company.vue'
import Payment from '../components/Payment/Payment.vue'
import TimeAxis from '../components/TimeAxis/TimeAxis.vue'
Vue.use(VueRouter)

const routes = [{
    path: '/',
    redirect: '/Login'
  },
  {
    path: '/Login',
    component: Login
  },
  {
    path: '/Home',
    component: Home,
    redirect: '/Welcome',
    children: [{
        path: '/Welcome',
        component: Welcome
      },
      {
        path: '/Users',
        component: Users
      },
      {
        path: '/Roles',
        component: Roles
      },
      {
        path: '/Orders',
        component: Orders
      },
      {
        path: '/Address',
        component: Address
      },
      {
        path: '/Car',
        component: Car
      },
      {
        path: '/Label',
        component: Label
      },
      {
        path: '/CateGory',
        component: CateGory
      },
      {
        path: '/Company',
        component: Company
      },
      {
        path: '/Payment',
        component: Payment
      },
      {
        path: '/TimeAxis',
        component: TimeAxis
      },
      {
        path: '/orderReport',
        component: orderReport
      },
      {
        path: '/areaReport',
        component: areaReport
      },
      {
        path: '/cateReport',
        component: cateReport
      },
      {
        path: '/payReport',
        component: payReport
      },
    ]
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  routes
});
//挂载路由导航守卫
router.beforeEach((to, from, next) => {
  //to 将要访问路径
  //from代表从哪个路径跳转而来
  //next是一个函数，表示放行
  // next() 放行 next('/login') 强制跳转
  if (to.path == '/Login') return next(); //如果是访问login页面直接放行
  //获取token
  const tokenStr = window.sessionStorage.getItem('token');
  if (!tokenStr) return next('/Login'); //如果没有token 强制跳转login页面
  next();
})

export default router