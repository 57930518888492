//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 1. 引入 echarts 按需引入
import * as echarts from 'echarts'
import moment from 'moment'
import _ from 'lodash'
export default {
  data() {
    return {
      activeName: 'first',
      loading: false,
      // 用户数统计
      totalUser: '',
      companyUser: '',
      cleanMan: '',
      // 订单数统计
      totalOrder: '',
      completedOrder: '',
      appointmentOrder: '',
      transportOrder: '',
      queryInfo: {
        current: 1,
        pageSize: 10000,
      },
      column1: 4,
      column2: 5,
      column3: 3,
      totalUserDialog: false,
      companyUserDialog: false,
      cleanManDialog: false,
      totalOrderDialog: false,
      appointmentOrderDialog: false,
      transportOrderDialog: false,
      completedOrderDialog: false,
      xData: ['总用户数', '企业用户数', '清运人数'],
      seriesData: [],
      xData2: ['总订单数', '待预约订单', '待运输订单', '已完成订单'],
      seriesData2: [],
      totalTopUp: 0,
      totalDeductions: 0,
      xData3: ['总充值金额', '总订单扣款金额'],
      seriesData3: [],
      companyPriceList: [],
      companyPriceDialog: false,
      queryParams: {
        companyId: '',
      },
      selectCompanyKeys: [],
      companyProps: {
        value: 'id',
        label: 'userName',
      },
      companyTopup: 0,
      companyDeductions: 0,
      companyList: [],
      TopupList: [],
      DeductionsList: [],
      TopupDialog: false,
      DeductionsDialog: false,
      queryInfo1: {
        current: 1,
        pageSize: 200,
      },
      total1: 0,
      queryParams1: {
        time: '',
      },
      beginTime: '',
      finishTime: '',
    }
  },
  created() {
  },
  methods: {
    // 时间转换
    format() { },
    // 昨日按钮
    yesterday() {
      this.totalTopUp = 0
      this.totalDeductions = 0
      this.loading = true
      let beginTime = moment(moment().add(-1, 'days').startOf('day').valueOf()).format('YYYY-MM-DD HH:mm:ss')
      let end = moment(moment().add(-1, 'days').endOf('day').valueOf()).format('YYYY-MM-DD HH:mm:ss')
      this.queryParams1.time = [beginTime, end]
      this.drawUser2(beginTime, end)
      this.drawOrder2(beginTime, end)
      this.drawPrice2(beginTime, end)
    },
    // 今日按钮
    today() {
      this.totalTopUp = 0
      this.totalDeductions = 0
      this.loading = true
      let beginTime = moment(moment().startOf('day').valueOf()).format('YYYY-MM-DD HH:mm:ss')
      let end = moment(moment().valueOf()).format('YYYY-MM-DD HH:mm:ss')
      this.queryParams1.time = [beginTime, end]
      this.drawUser2(beginTime, end)
      this.drawOrder2(beginTime, end)
      this.drawPrice2(beginTime, end)
    },
    // 七日按钮
    sevenDay() {
      this.totalTopUp = 0
      this.totalDeductions = 0
      this.loading = true
      let beginTime = moment(moment().add(-7, 'days').startOf('day').valueOf()).format('YYYY-MM-DD HH:mm:ss')
      let end = moment(moment().valueOf()).format('YYYY-MM-DD HH:mm:ss')
      this.queryParams1.time = [beginTime, end]
      this.drawUser2(beginTime, end)
      this.drawOrder2(beginTime, end)
      this.drawPrice2(beginTime, end)
    },
    // 三十日按钮
    ThirtyDays() {
      this.totalTopUp = 0
      this.totalDeductions = 0
      this.loading = true
      let beginTime = moment(moment().add(-30, 'days').startOf('day').valueOf()).format('YYYY-MM-DD HH:mm:ss')
      let end = moment(moment().valueOf()).format('YYYY-MM-DD HH:mm:ss')
      this.queryParams1.time = [beginTime, end]
      this.drawUser2(beginTime, end)
      this.drawOrder2(beginTime, end)
      this.drawPrice2(beginTime, end)
    },
    // 时间搜索 搜索按钮
    async handleQuery1() {
      console.log(this.beginTime)
      this.loading = true
      this.totalTopUp = 0
      this.totalDeductions = 0
      console.log(this.queryParams1.time)
      if (this.queryParams1.time == '' || this.queryParams1.time == null || this.queryParams1.time == undefined) {
        this.drawUser()
        this.drawOrder()
        this.drawPrice()
      } else {
        this.beginTime = this.queryParams1.time[0]
        this.finishTime = this.queryParams1.time[1]
        this.drawUser2(this.beginTime, this.finishTime)
        this.drawOrder2(this.beginTime, this.finishTime)
        this.drawPrice2(this.beginTime, this.finishTime)
      }

    },
    handleClick() { },
    async drawUser() {
      const { data: res } = await this.$http.post('/admin/user/statistics', {
        beginTime: "",
        finishTime: ""
      })
      if (res.code !== 100) return this.$message.error('总用户列表获取失败')
      this.totalUser = res.result.totalCount
      this.companyUser = res.result.firmCount
      this.cleanMan = res.result.clearCount
      this.seriesData = [this.totalUser, this.companyUser, this.cleanMan]
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById('main1'))
      // 绘制图表
      myChart.setOption({
        title: { text: '用户统计报表' },
        tooltip: { trigger: 'item' },
        xAxis: {
          data: this.xData,
        },
        yAxis: {},
        series: [
          {
            type: 'bar',
            barWidth: '20%',
            data: this.seriesData,
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: {
                    color: 'black',
                    fontSize: 12,
                  },
                },
              },
            },
          },
        ],
      })
    },
    async drawUser2(beginTime, end) {
      const { data: res } = await this.$http.post('/admin/user/statistics', {
        beginTime: beginTime,
        finishTime: end,
      })
      if (res.code !== 100) return this.$message.error('总用户列表获取失败')
      this.totalUser = res.result.totalCount
      this.companyUser = res.result.firmCount
      this.cleanMan = res.result.clearCount
      this.seriesData = [this.totalUser, this.companyUser, this.cleanMan]
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById('main1'))
      // 绘制图表
      myChart.setOption({
        title: { text: '用户统计报表' },
        tooltip: { trigger: 'item' },
        xAxis: {
          data: this.xData,
        },
        yAxis: {},
        series: [
          {
            type: 'bar',
            barWidth: '20%',
            data: this.seriesData,
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: {
                    color: 'black',
                    fontSize: 12,
                  },
                },
              },
            },
          },
        ],
      })
    },
    async drawOrder() {
      const { data: res } = await this.$http.post('/admin/user/statistics', {
        beginTime: "",
        finishTime: ""
      })
      if (res.code !== 100) return this.$message.error(res.msg)
      this.totalOrder = res.result.indentCount
      this.completedOrder = res.result.doneCount
      this.appointmentOrder = res.result.orderCount
      this.transportOrder = res.result.transportCount
      this.seriesData2 = [this.totalOrder, this.appointmentOrder, this.transportOrder, this.completedOrder]
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById('main2'))
      // 绘制图表
      myChart.setOption({
        title: { text: '订单统计报表' },
        tooltip: {},
        xAxis: {
          data: this.xData2,
        },
        yAxis: {},
        series: [
          {
            type: 'bar',
            barWidth: '25%',
            data: this.seriesData2,
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: {
                    color: 'black',
                    fontSize: 12,
                  },
                },
              },
            },
          },
        ],
      })
    },
    async drawOrder2(beginTime, end) {
      const { data: res } = await this.$http.post('/admin/user/statistics', {
        beginTime: beginTime,
        finishTime: end,
      })
      if (res.code !== 100) return this.$message.error(res.msg)
      this.totalOrder = res.result.indentCount
      this.completedOrder = res.result.doneCount
      this.appointmentOrder = res.result.orderCount
      this.transportOrder = res.result.transportCount
      this.seriesData2 = [this.totalOrder, this.appointmentOrder, this.transportOrder, this.completedOrder]
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById('main2'))
      // 绘制图表
      myChart.setOption({
        title: { text: '订单统计报表' },
        tooltip: {},
        xAxis: {
          data: this.xData2,
        },
        yAxis: {},
        series: [
          {
            type: 'bar',
            barWidth: '25%',
            data: this.seriesData2,
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: {
                    color: 'black',
                    fontSize: 12,
                  },
                },
              },
            },
          },
        ],
      })
    },
    async drawPrice() {
      const { data: res } = await this.$http.post('/admin/user/statistics', {
        beginTime: "",
        finishTime: ""
      })
      if (res.code !== 100) return this.$message.error(res.msg)
      this.loading = false
      this.totalTopUp = res.result.totalSum
      this.totalDeductions = Math.abs(res.result.blanketOrderSum)
      this.seriesData3 = [this.totalTopUp, this.totalDeductions]
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById('main3'))
      // 绘制图表
      myChart.setOption({
        title: { text: '预付款统计报表' },
        tooltip: {},
        xAxis: {
          data: this.xData3,
        },
        yAxis: {},
        series: [
          {
            type: 'bar',
            barWidth: '7.5%',
            data: this.seriesData3,
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: {
                    color: 'black',
                    fontSize: 12,
                  },
                },
              },
            },
          },
        ],
      })
      this.loading = false
    },
    async drawPrice2(beginTime, end) {
      const { data: res } = await this.$http.post('/admin/user/statistics', {
        beginTime: beginTime,
        finishTime: end,
      })
      if (res.code !== 100) return this.$message.error(res.msg)
      this.loading = false
      this.totalTopUp = res.result.totalSum
      this.totalDeductions = Math.abs(res.result.blanketOrderSum)
      this.seriesData3 = [this.totalTopUp, this.totalDeductions]
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById('main3'))
      // 绘制图表
      myChart.setOption({
        title: { text: '预付款统计报表' },
        tooltip: {},
        xAxis: {
          data: this.xData3,
        },
        yAxis: {},
        series: [
          {
            type: 'bar',
            barWidth: '7.5%',
            data: this.seriesData3,
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: {
                    color: 'black',
                    fontSize: 12,
                  },
                },
              },
            },
          },
        ],
      })
      this.loading = false
    },
  },
  mounted() {
    this.handleQuery1()
  },
}
