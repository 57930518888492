//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
export default {
  data() {
    return {
      loading: true,
      orderList: [],
      queryInfo: {
        current: 1,
        pageSize: 50,
      },
      total: 0,
      editOrderDialog: false,
      editOrderForm: {},
      editOrderFormRules: {},
      //运输状态
      selectstatustKeys: [],
      statusList: [
        {
          value: 1,
          label: '待预约',
        },
        {
          value: 2,
          label: '待运输',
        },
        {
          value: 3,
          label: '已完成',
        },
      ],
      selectState: [],
      selectKeys: [],
      List: [],
      List2: [],
      Props: {
        value: 'id',
        label: 'name',
        children: 'categoryList',
        checkStrictly: true,
      },
      //选择运输模式
      selectTransportKeys: [],
      transportList: [],
      transport2List: [],
      transportProps: {
        value: 'id',
        label: 'name',
        children: 'categoryList',
        checkStrictly: true,
      },
      //选择清算单位
      selectUnitKeys: [],
      unitList: [],
      unit2List: [],
      unitProps: {
        value: 'id',
        label: 'name',
        children: 'categoryList',
        checkStrictly: true,
      },
      //搜索对象
      queryParams: {
        orderNum: undefined,
        cleanerId: undefined,
        sponsorId: undefined,
        addressId: undefined,
        time: undefined,
        vehicle: undefined,
        status: undefined,
      },
      removeList: {},
      //清运人列表
      cleanList: [],
      cleanProps: {
        value: 'id',
        label: 'avatar',
      },
      selectCleanKeys: [],
      //预约人列表
      subscribe: [],
      selectUserKeys: [],
      userProps: {
        value: 'id',
        label: 'name',
      },
      subscribe2: [],
      selectUser2Keys: [],
      user2Props: {
        value: 'id',
        label: 'linkman',
      },
      // 删除loading
      deleteLoading: false,
      // 司机图片地址
      driverImageAddress: [],
      // 企业图片地址
      firmImageAddress: [],
      totalOrderList: [],
      isshowTable: false,
      beginTime: undefined,
      finishTime: undefined,
      // 选择导出数据数组
      daoChuChoseList: [],
      // 是否推送
      selectIsPushKupono: [],
      selectIsPushStandingBook: [],
      isOptions: [
        {
          value: 0,
          label: '未推送'
        },
        {
          value: 1,
          label: '已推送'
        }
      ],
      // 公司搜索
      selectUserNameKeys: undefined,
      userNameList: [],
      changeCompanyLoading: false,
      // 编辑loading
      editLoading: false,
      isEdit: true,
      carString: '',
      carList: [],
      ljType: [],
    }
  },
  created() {
    this.getUserNameList()
    this.getCarList()
    this.getOrderList()
    this.getTypeList()
    this.getUserList()
    // this.getSubscribeList()
    // this.getLinkManList()
  },
  methods: {
    //获取车辆列表
    async getCarList() {
      const { data: res } = await this.$http.post('/vehicle/inquire', {
        page: {
          current: 1,
          pageSize: 9999,
        },
        data: {
          isValid: 1,
        },
      })
      if (res.code !== 100) return this.$message.error('获取车辆列表失败')
      this.carList = res.result.records
    },
    // 公司搜索
    remoteMethod2(query) {
      if (query !== '') {
        this.changeCompanyLoading = true
        setTimeout(() => {
          this.selectInput2(query)
        }, 200)
      } else {
        this.getUserNameList()
        // this.conpanyList = []
      }
    },
    async selectInput2(query) {
      const { data: res } = await this.$http.post('/company/query', {
        data: {
          userName: query,
        },
        page: {
          current: 1,
          pageSize: 10,
        },
      })
      this.userNameList = res.result.records
      this.changeCompanyLoading = false
    },
    //获取所属用户列表
    async getUserNameList() {
      const { data: res } = await this.$http.post('/company/query', {
        page: {
          current: 1,
          pageSize: 10,
        },
      })
      if (res.code !== 100) return this.$message.error('获取用户列表失败')
      this.userNameList = res.result.records
    },
    // 选择是否推送联单
    changePushKupono() {
      this.queryParams.isPushKupono = this.selectIsPushKupono[this.selectIsPushKupono.length - 1]
    },
    changePushStandingBook() {
      this.queryParams.isPushStandingBook = this.selectIsPushStandingBook[this.selectIsPushStandingBook.length - 1]
    },
    // 推送联单
    async uploadLian() {
      this.loading = true
      if (this.daoChuChoseList.length == 0) {
        this.$message.error('请选择需要推送的数据')
        this.loading = false
      } else {
        let data = JSON.parse(JSON.stringify(this.daoChuChoseList))
        for (let i = 0; i < data.length; i++) {
          data[i].vehicle = JSON.stringify(data[i].vehicle)
        }
        const { data: res } = await this.$http.post('/orders/pushKupono', data)
        if (res.code !== 100) {
          this.loading = false
          return this.$message.error(res.msg)
        }
        this.loading = false
        this.$message.success('推送成功')
        this.$refs.multipleTable.clearSelection();
      }
    },
    // 推送台账
    async uploadTai() {
      this.loading = true
      // console.log(this.daoChuChoseList)
      if (this.daoChuChoseList.length == 0) {
        this.$message.error('请选择需要推送的数据')
        this.loading = false
      } else {
        let data = JSON.parse(JSON.stringify(this.daoChuChoseList))
        for (let i = 0; i < data.length; i++) {
          data[i].vehicle = JSON.stringify(data[i].vehicle)
        }
        const { data: res } = await this.$http.post('/orders/pushStandingBook', data)
        if (res.code !== 100) {
          this.loading = false
          return this.$message.error(res.msg)
        }
        this.loading = false
        this.$message.success('推送成功')
        this.$refs.multipleTable.clearSelection();
      }
    },
    // 筛选 状态I
    changStateAction() {
      this.queryParams.status = this.selectState[this.selectState.length - 1]
    },
    // table选择框
    handleSelectionChange(val) {
      this.daoChuChoseList = val
    },
    // 第二种导出功能
    exportExcel(elementName, fileName) {
      const wb = XLSX.utils.table_to_book(this.clearHead(elementName), { raw: true });
      const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' });
      FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
    },
    // 导出时，去除展开和操作列
    clearHead(elementName) {
      const tableDom = document.querySelector('#' + elementName).cloneNode(true);
      const tableHeader = tableDom.querySelector('.el-table__header-wrapper');
      const tableBody = tableDom.querySelector('.el-table__body');
      tableHeader.childNodes[0].append(tableBody.childNodes[1]);
      const headerDom = tableHeader.childNodes[0].querySelectorAll('th');
      for (const key in headerDom) {
        if (headerDom[key].innerText === '操作' || headerDom[key].innerText === '查看') {
          headerDom[key].remove();
        }
      }
      // 清理掉checkbox 和操作的button
      const tableList = tableHeader.childNodes[0].childNodes[2].querySelectorAll('td');
      for (let key = 0; key < tableList.length; key++) {
        if (tableList[key].querySelectorAll('.el-button').length > 0) {
          tableList[key].remove();
        }
      }
      return tableHeader;
    },
    daochuAction() {
      if (this.daoChuChoseList.length == 0) {
        this.getTotalOrderList()
      } else {
        this.choseDaoChuAction()
      }
    },
    choseDaoChuAction() {
      this.loading = true
      this.totalOrderList = this.daoChuChoseList
      setTimeout(() => {
        this.loading = false
        this.$refs.multipleTable.clearSelection();
        this.exportExcel('educe-table', '订单列表')
      }, 1000);
    },
    // 获取所有订单列表
    async getTotalOrderList() {
      this.loading = true
      const { data: res } = await this.$http.post('/orders/inquire', {
        page: {
          current: 1,
          pageSize: 99999,
        },
        data: {
          orderNum: this.queryParams.orderNum,
          // sponsorId: this.queryParams.sponsorId,
          cleanerId: this.queryParams.cleanerId,
          // addressId: this.queryParams.addressId,
          isBackground: '后台',
          beginTime: this.beginTime,
          finishTime: this.finishTime,
          vehicle: this.queryParams.vehicle,
          status: this.queryParams.status,
          isPushStandingBook: this.queryParams.isPushStandingBook,
          isPushKupono: this.queryParams.isPushKupono,
          companyName: this.selectUserNameKeys
        },
      })
      if (res.code !== 100) return this.$message.error('查询订单列表失败')
      this.totalOrderList = res.result.records
      console.log(this.totalOrderList)
      for (let i = 0; i < this.totalOrderList.length; i++) {
        if (this.totalOrderList[i].vehicle !== '' && this.totalOrderList[i].vehicle !== null) {
          this.totalOrderList[i].vehicle = JSON.parse(this.totalOrderList[i].vehicle)
        }
      }
      setTimeout(() => {
        this.loading = false
        this.exportExcel('educe-table', '订单列表')
      }, 1000);
    },
    //获取用户列表
    async getUserList() {
      const { data: res } = await this.$http.post('/admin/user/userInDetail', {
        page: {
          current: 1,
          pageSize: 99999,
        },
        data: {
          roleId: 2
        }
      })
      if (res.code !== 100) return this.$message.error(res.msg)
      this.cleanList = res.result.records.filter((item) => item.roleId == 2)
      // this.subscribe = res.result.records.filter((item) => item.classId == 1)
    },
    //获取用户列表
    async getSubscribeList() {
      const { data: res } = await this.$http.post('/admin/user/userInDetail', {
        page: {
          current: 1,
          pageSize: 99999,
        },
        data: {
          classId: 1
        }
      })
      if (res.code !== 100) return this.$message.error(res.msg)
      // this.cleanList = res.result.records.filter((item) => item.roleId == 2)
      this.subscribe = res.result.records.filter((item) => item.classId == 1)
    },
    //获取联系人列表
    async getLinkManList() {
      const { data: res } = await this.$http.post('/address/inquire', {
        page: {
          current: 1,
          pageSize: 99999,
        },
        data: {
          isValid: 1
        }
      })
      if (res.code !== 100) return this.$$message.error(res.msg)
      this.subscribe2 = res.result.records.filter((item) => item.linkman !== null && item.cid !== 35)
    },
    //搜索按钮
    async handleQuery() {
      // console.log(this.selectUserNameKeys)
      if (this.queryParams.time !== undefined && this.queryParams.time !== null) {
        this.beginTime = this.queryParams.time[0]
        this.finishTime = this.queryParams.time[1]
      } else {
        this.beginTime = undefined
        this.finishTime = undefined
      }
      if (this.selectIsPushKupono.length == 0) {
        this.queryParams.isPushKupono = undefined
      }
      if (this.selectIsPushStandingBook.length == 0) {
        this.queryParams.isPushStandingBook = undefined
      }
      this.loading = true
      const { data: res } = await this.$http.post('/orders/inquire', {
        data: {
          orderNum: this.queryParams.orderNum,
          // sponsorId: this.queryParams.sponsorId,
          cleanerId: this.queryParams.cleanerId,
          // addressId: this.queryParams.addressId,
          isBackground: '后台',
          beginTime: this.beginTime,
          finishTime: this.finishTime,
          vehicle: this.queryParams.vehicle,
          status: this.queryParams.status,
          isPushStandingBook: this.queryParams.isPushStandingBook,
          isPushKupono: this.queryParams.isPushKupono,
          companyName: this.selectUserNameKeys
        },
        page: {
          current: this.queryInfo.current,
          pageSize: this.queryInfo.pageSize,
        },
      })
      if (res.code !== 100) {
        this.loading = false
        return this.$message.error(res.msg)
      }
      this.loading = false
      this.orderList = res.result.records
      for (let i = 0; i < this.orderList.length; i++) {
        if (this.orderList[i].vehicle !== '' && this.orderList[i].vehicle !== null) {
          this.orderList[i].vehicle = JSON.parse(this.orderList[i].vehicle)
        }
      }
      this.total = res.result.total
    },
    //重置按钮
    resetQuery() {
      this.loading = true
      this.finishTime = undefined
      this.beginTime = undefined
      this.selectUserNameKeys = undefined
      this.queryInfo.current = 1
      this.queryInfo.pageSize = 50
      this.selectState = []
      this.queryParams = {}
      this.$refs.queryForm.resetFields()
      this.selectUserKeys = []
      this.selectCleanKeys = []
      this.selectUser2Keys = []
      this.selectIsPushKupono = []
      this.selectIsPushStandingBook = []
      this.$refs.multipleTable.clearSelection();
      this.getOrderList()
    },
    // //打开修改订单对话框 查询垃圾类型
    async getTypeList() {
      const { data: res } = await this.$http.post('/caterory/query', {
        page: {
          current: this.queryInfo.current,
          pageSize: this.queryInfo.pageSize,
        },
      })
      if (res.code !== 100) return this, $message.error('查询类型失败')
      this.List = res.result.records.filter((item) => item.id == 1)
      this.List2 = this.List[0].categoryList
      this.ljType = res.result.records.filter((item) => item.id == 41)[0].categoryList
      this.transportList = res.result.records.filter((item) => item.id == 45)
      this.transport2List = this.transportList[0].categoryList
    },
    //编辑支付方式 选择器动作
    handleChange() {
      this.editOrderForm.settleClass = this.selectSetKeys[this.selectSetKeys.length - 1]
    },
    //编辑 选择运输状态
    handleChange2() {
      this.editOrderForm.status = this.selectstatustKeys[this.selectstatustKeys.length - 1]
    },
    handleChange3() {
      this.editOrderForm.garbageClassId = this.selectKeys[this.selectKeys.length - 1]
    },
    handleChange4() {
      this.editOrderForm.transportTypeId = this.selectTransportKeys[this.selectTransportKeys.length - 1]
    },
    handleChange5() {
      this.editOrderForm.garbageUnitId = this.selectUnitKeys[this.selectUnitKeys.length - 1]
    },
    //搜索 选择预约人
    handleChange6() {
      this.queryParams.sponsorId = this.selectUserKeys[this.selectUserKeys.length - 1]
    },
    //搜索 选择清运人
    handleChange7() {
      this.queryParams.cleanerId = this.selectCleanKeys[this.selectCleanKeys.length - 1]
    },
    //搜索 选择预约人
    handleChange8() {
      this.queryParams.addressId = this.selectUser2Keys[this.selectUser2Keys.length - 1]
    },
    //获取订单列表
    async getOrderList() {
      this.loading = true
      const { data: res } = await this.$http.post('/orders/inquire', {
        page: {
          current: this.queryInfo.current,
          pageSize: this.queryInfo.pageSize,
        },
        data: {
          orderNum: this.queryParams.orderNum,
          // sponsorId: this.queryParams.sponsorId,
          cleanerId: this.queryParams.cleanerId,
          // addressId: this.queryParams.addressId,
          isBackground: '后台',
          beginTime: this.beginTime,
          finishTime: this.finishTime,
          vehicle: this.queryParams.vehicle,
          status: this.queryParams.status,
          isPushStandingBook: this.queryParams.isPushStandingBook,
          isPushKupono: this.queryParams.isPushKupono,
          companyName: this.selectUserNameKeys
        },
      })
      if (res.code !== 100) return this.$message.error('查询订单列表失败')
      this.orderList = res.result.records
      this.total = res.result.total
      for (let i = 0; i < this.orderList.length; i++) {
        if (this.orderList[i].vehicle !== '' && this.orderList[i].vehicle !== null) {
          this.orderList[i].vehicle = JSON.parse(this.orderList[i].vehicle)
        }
      }
      // console.log(this.orderList)
      this.loading = false
    },
    //打开修改订单对话框
    async OpenEditOrderDialog(id) {
      const { data: res } = await this.$http.post('/orders/getById', { id: id })
      if (res.code !== 100) return this.$message.error('查询订单失败')
      this.selectKeys = res.result.garbageClassId
      this.selectTransportKeys = res.result.transportTypeId
      this.selectUnitKeys = res.result.garbageUnitId
      this.editOrderForm = res.result
      this.selectstatustKeys = res.result.status
      this.editOrderDialog = true
      let data = JSON.parse(res.result.vehicle)
      this.carString = data.plateNumber + data.name + data.phone
      this.firmImageAddress = JSON.parse(JSON.stringify(this.editOrderForm.firmImageAddress)).split(',')
      this.driverImageAddress = JSON.parse(JSON.stringify(this.editOrderForm.driverImageAddress)).split(',')
    },
    changeCar(item) {
      this.editOrderForm.vehicle = JSON.stringify(item)
    },
    // 点击修改按钮
    async OpenEditOrderDialog2(id) {
      const { data: res } = await this.$http.post('/orders/getById', { id: id })
      if (res.code !== 100) return this.$message.error('查询订单失败')
      this.selectKeys = res.result.garbageClassId
      this.selectTransportKeys = res.result.transportTypeId
      this.selectUnitKeys = res.result.garbageUnitId
      this.editOrderForm = res.result
      this.selectstatustKeys = res.result.status
      let data = JSON.parse(res.result.vehicle)
      this.carString = data.plateNumber + data.name + data.phone
      this.editOrderDialog = true
      this.firmImageAddress = JSON.parse(JSON.stringify(this.editOrderForm.firmImageAddress)).split(',')
      this.driverImageAddress = JSON.parse(JSON.stringify(this.editOrderForm.driverImageAddress)).split(',')
      this.isEdit = false
    },
    // 修改订单重量
    async editOrderWeight() {
      this.editLoading = true
      if (this.isEdit == true) {
        this.editLoading = false
        this.getOrderList()
        this.isEdit == true
        this.editOrderDialog = false
      } else {
        const { data: res } = await this.$http.post('/orders/upWeight', this.editOrderForm)
        if (res.code !== 100) {
          this.editLoading = false
          return this.$message.error('修改失败')
        }
        this.editLoading = false
        this.$message.success('修改成功')
        this.getOrderList()
        this.isEdit == true
        this.editOrderDialog = false
      }
    },
    // 关闭订单
    closeOrderDialog() {
      this.editOrderForm = {}
      this.driverImageAddress = []
      this.firmImageAddress = []
      this.isEdit = true
      this.carString = ''
      this.editOrderDialog = false
    },
    //监听 pagesize改变事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getOrderList()
    },
    //监听页码值改变事件
    handleCurrentChange(newPage) {
      this.queryInfo.current = newPage
      this.getOrderList()
    },
  },
}
