//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      timeAxisList: [],
      queryParams: {},
      queryInfo: {
        current: 1,
        pageSize: 50,
      },
      total: 0,
      loading: true,
    }
  },
  created() {
    this.getAjaxList()
  },
  methods: {
    async getAjaxList() {
      const { data: res } = await this.$http.post('/timeAxis/inquire', {
        page: {
          current: this.queryInfo.current,
          pageSize: this.queryInfo.pageSize,
        },
      })
      if (res.code !== 100) return this.$message.error('获取日志列表失败')
      this.timeAxisList = res.result.records
      this.total = res.result.total
      this.loading = false
    },
    //监听 pagesize改变事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getAjaxList()
    },
    //监听页码值改变事件
    handleCurrentChange(newPage) {
      this.queryInfo.current = newPage
      this.getAjaxList()
    },
  },
}
