//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { GVerify } from '../assets/js/verifyCode'
export default {
  data() {
    return {
      loginForm: {
        name: '',
        password: '',
        verifyCode: '',
      },
      loginFormRules: {
        name: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        verifyCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      },
      // 登录加载
      loginLoading: false,
    }
  },
  created() { },
  methods: {
    //重置表单
    resetLoginForm() {
      this.$refs.loginFormRef.resetFields()
      window.alert('请联系开发商')
    },
    // 刷新验证码
    refresh() {
      this.verifyCode = new GVerify('v_container')
    },
    login() {
      // 获取验证码
      var verifyCode = this.loginForm.verifyCode
      var verifyFlag = this.verifyCode.validate(verifyCode)
      if (!verifyFlag) {
        this.$message.error('验证码错误')
        this.$refs.loginFormRef.resetFields()
        this.verifyCode = new GVerify('v_container')
        return
      }
      //调用接口 查询登录信息
      //并添加token临时令牌
      this.$refs.loginFormRef.validate(async (valid) => {
        if (!valid) return
        const res = await this.$http.post('admin/login/status', {
          name: this.loginForm.name,
          password: this.loginForm.password,
        })
        // console.log(res)
        if (res.data.code !== 100) return this.$message.error(res.data.msg)
        this.$message.success('登录成功')
        window.sessionStorage.setItem('token', res.data.result.token)
        window.sessionStorage.setItem('username', res.data.result.name)
        window.sessionStorage.setItem('usernameId', res.data.result.id)
        window.sessionStorage.setItem('activePath', '/welcome')
        this.$store.state.userList = res.data.result
        window.sessionStorage.setItem('roleId', res.data.result.roleId)
        // this.$store.state.classId = res.data.result.classId
        // console.log(this.$store.state.classId)
        // console.log(this.$store.state.userList)
        this.$router.push('/Home')
      })
    },
  },
  mounted() {
    this.verifyCode = new GVerify('v_container')
  },
}
