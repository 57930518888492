import Vue from 'vue'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import {Form,FormItem,Input,Button,Message,
    Container,Header,Aside,Main,Menu,Submenu,
    MenuItemGroup,MenuItem,Cascader,Loading,Table,TableColumn} from 'element-ui'
Vue.use(Element)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Button)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItemGroup)
Vue.use(MenuItem)
Vue.use(Cascader)
Vue.use(Loading)
Vue.use(Table)
Vue.use(TableColumn)
Vue.prototype.$message = Message